import React, { Component } from 'react';
import { Drawer, message } from 'antd';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import { DrawerHeader } from './';
import { Loader } from '../atoms';

import { VendorAdapter } from '../../adapters/index';

class DocumentPreview extends Component {
    state = {
        loading: false,
        documment_data: null
    }

    componentDidMount = () => {
        this.setState({
            loading: true
        }, () => {
            VendorAdapter.getBlobDocument(this.props.documentUrl)
                .then((document_data) => {
                    this.setState({
                        document_data,
                        loading: false
                    });
                }).catch((error) => {
                    message.error(error.message);
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onClose();
                    });
                });
        });
    }

    render = () => {
        const { title, onClose } = this.props;
        const { loading, document_data } = this.state;
        return (
            <Drawer
                width={700}
                placement="right"
                maskClosable={false}
                closable={false}
                onClose={onClose}
                visible={true}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}>
                <DrawerHeader title={title} onClose={onClose} />
                {loading && (
                    <div className="flex flex-row justify-content-center py-5">
                        <Loader />
                    </div>
                )}
                {!loading && (
                    <div className="p-4">
                        {!!document_data && (
                            <DocViewer
                                className={this.props.noDownload ? 'no-download' : ''}
                                pluginRenderers={DocViewerRenderers}
                                documents={[{ uri: document_data }]}
                                config={{
                                    header: {
                                        disableFileName: true,
                                        disableHeader: true,
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
            </Drawer>
        );
    }
}

export default DocumentPreview;