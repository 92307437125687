export const GET_AUDITS = {
    "TotalCount": 2553,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "aee5d881-817f-4a9f-8eca-c2e1235ba5e5",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "9cf91de7-cf0d-468d-869a-340e0ef84d17",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "547a1e73-5ff7-4c56-b497-3f7c70a8bd13",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "c9537c12-2027-4522-848c-5fcb89b40026",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "ff008a5f-6818-4c42-b835-80fb8515ef8d",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "7338caf0-f532-4613-9cce-114636272321",
        "FullName": "Clerk Lambert",
        "Section": "Proposal",
        "Action": "submit",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": true
    }, {
        "Id": "5c8ec674-702c-4b16-87d6-bb7b78da8aea",
        "FullName": "Test User1",
        "Section": "Procurements",
        "Action": "Create",
        "CreatedDate": "Nov 25, 2021",
        "IsVendor": false
    }, {
        "Id": "c93376a2-8ce5-48fe-bb78-10200f13f7d7",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 24, 2021",
        "IsVendor": false
    }, {
        "Id": "3d57e27a-a851-4394-9a42-2a34db919b39",
        "FullName": "Test User1",
        "Section": "RFQ Submission Comment",
        "Action": "submit",
        "CreatedDate": "Nov 24, 2021",
        "IsVendor": false
    }, {
        "Id": "3b7950a0-8767-4d28-9a56-e803e4b0879a",
        "FullName": "Test User1",
        "Section": "Contract",
        "Action": "update Vendor on Contract",
        "CreatedDate": "Nov 24, 2021",
        "IsVendor": false
    }]
}