import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { EmailMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Emails`;

export const getEmails = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(EmailMock.GET_EMAILS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetEmails?${Util.objectToQueryString(params)}`, 'get');
}