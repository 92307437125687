import React, { Component } from "react";

class DashboardIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 16C4.3457 16 3 14.6543 3 13V2H0V0H3H21H24V2H21V13C21 14.6543 19.6543 16 18 16H13V18.1843C14.1615 18.5984 15 19.6981 15 21C15 22.6543 13.6543 24 12 24C10.3457 24 9 22.6543 9 21C9 19.6981 9.8385 18.5984 11 18.1843V16H6ZM11 21C11 21.5513 11.4482 22 12 22C12.5518 22 13 21.5513 13 21C13 20.4487 12.5518 20 12 20C11.4482 20 11 20.4487 11 21ZM19 13V2H5V13C5 13.5513 5.44824 14 6 14H18C18.5518 14 19 13.5513 19 13ZM6.9999 6H8.9999V12H6.9999V6ZM13.0001 8.00002H11.0001V12H13.0001V8.00002ZM15 3.99998H17V12H15V3.99998Z"
                    fill="white"
                />
            </svg>
        )
    }
}

export default DashboardIcon;
