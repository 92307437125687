import { combineReducers } from 'redux';

import configReducer from './config_reducer';
import sidebarReducer from './sidebar_reducer';
import userReducer from './user_reducer';
import tourReducer from './tour_reducer';

const rootReducer = combineReducers({
    config: configReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    tour: tourReducer
});

export default rootReducer;