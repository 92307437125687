import React, { Component } from "react";
import { Table } from "antd";

import { Loader } from "../atoms";

class CustomTable extends Component {
    render = () => {
        const { props } = this;
        let tableConfig = {
            loading: {
                spinning: props.loading ? props.loading : false,
                indicator: <Loader />,
            },
            rowKey: props.rowKey || "id",
            onRow: props.onRow,
            columns: props.columns,
            dataSource: props.data
        };

        if (props.size) {
            tableConfig.size = props.size;
        }

        if (props.useFixedHeader) {
            tableConfig.useFixedHeader = true;
        }

        if (props.bodyStyle) {
            tableConfig.bodyStyle = props.bodyStyle;
        }

        if (props.paginated && props.paginationProps) {
            tableConfig.pagination = {
                total: props.paginationProps.totalElements,
                current: props.paginationProps.currentPage,
                pageSize: props.paginationProps.size,
                onChange: props.paginationProps.onChange || (() => null),
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                position: props.paginationProps.position || "both",
            };

            if (props.sizeControlProps && props.sizeControlProps.showSizeControl) {
                tableConfig.pagination.showSizeChanger = true;
                tableConfig.pagination.onShowSizeChange = (page, size) =>
                    props.sizeControlProps && props.sizeControlProps.setPageSize(size);
                tableConfig.pagination.pageSizeOptions = [
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100"
                ];
            }
        } else {
            tableConfig.pagination = false;
        }

        if (props.selection && props.selection.allowSelect) {
            tableConfig.rowSelection = {
                selectedRowKeys: props.selection.rows,
                onChange: props.selection.onChange,
                onSelect: props.selection.onSelect
            };
        }

        if (props.title) {
            tableConfig.title = props.title;
        }
        return (
            <div style={{ overflowX: "auto" }}>
                <Table {...tableConfig} className={props.tableClass || ''} />
            </div>
        );

    }
}

export default CustomTable;