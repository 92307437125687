import React, { Component } from "react";

class SettingsIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    d="M18.2112 11.0115C18.2112 9.11516 16.6812 7.57241 14.8005 7.57241C12.9198 7.57241 11.3898 9.11516 11.3898 11.0115V11.0534H0V12.9466H11.3898V12.9885C11.3898 14.8848 12.9198 16.4276 14.8005 16.4276C16.6812 16.4276 18.2112 14.8848 18.2112 12.9885V12.9466H24V11.0534H18.2112V11.0115ZM16.3338 12.9885C16.3338 13.8409 15.6459 14.5345 14.8005 14.5345C13.9552 14.5345 13.2673 13.8409 13.2673 12.9885V11.0115C13.2673 10.1591 13.9552 9.46551 14.8005 9.46551C15.6459 9.46551 16.3338 10.1591 16.3338 11.0115V12.9885ZM11.6089 3.48104V3.43914C11.6089 1.54275 10.0789 0 8.19817 0C6.31748 0 4.78748 1.54275 4.78748 3.43914V3.48104H0V5.37414H4.78748V5.41604C4.78748 7.31242 6.31748 8.85518 8.19817 8.85518C10.0789 8.85518 11.6089 7.31242 11.6089 5.41604V5.37414H24V3.48104H11.6089ZM9.73142 5.41604C9.73142 6.26844 9.04353 6.96207 8.19817 6.96207C7.35282 6.96207 6.66493 6.26844 6.66493 5.41604V3.43914C6.66493 2.58674 7.35282 1.8931 8.19817 1.8931C9.04353 1.8931 9.73142 2.58674 9.73142 3.43914V5.41604ZM11.6089 18.584C11.6089 16.6876 10.0789 15.1448 8.19817 15.1448C6.31748 15.1448 4.78748 16.6876 4.78748 18.584V18.6259H0V20.519H4.78748V20.5609C4.78748 22.4572 6.31748 24 8.19817 24C10.0789 24 11.6089 22.4572 11.6089 20.5609V20.519H24V18.6259H11.6089V18.584ZM9.73142 20.5609C9.73142 21.4133 9.04353 22.1069 8.19817 22.1069C7.35282 22.1069 6.66493 21.4133 6.66493 20.5609V18.584C6.66493 17.7316 7.35282 17.0379 8.19817 17.0379C9.04353 17.0379 9.73142 17.7316 9.73142 18.584V20.5609Z"
                    fill="#5E5E5E"
                />
            </svg>
        )
    }
}

export default SettingsIcon;
