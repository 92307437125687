import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";

import { Layout, Row, Col, Form, Input, Alert, Button, Checkbox } from "antd";
import { RightOutlined } from '@ant-design/icons';

import { updateUserData } from '../../../../redux/actions/user_actions';

import logo from "../../../assets/images/logo.png";
import { VendorAdapter } from '../../../../adapters/index';

const { Content } = Layout;

class VendorSignup extends Component {
    signupForm = React.createRef();
    state = {
        signup_error: '',
        processing: false
    }

    componentDidMount = () => {
        if (!this.props.user?.access_token || this.props.user?.role !== 'vendors' || this.props.user?.has_accepted_terms) {
            this.props.history.push('/');
        }
    }

    resetError = () => {
        this.setState({ signup_error: '' });
    }

    handleSubmit = () => {
        this.signupForm.current.validateFields().then((values) => {
            this.setState({ processing: true }, () => {
                VendorAdapter.vendorAcceptTermsAndConditions().then(() => {
                    this.props.updateUserData({ has_accepted_terms: true });
                    this.setState({ processing: false }, () => {
                        this.props.history.push('/vendors/registration');
                    });
                }).catch((signup_error) => {
                    this.setState({
                        processing: false,
                        signup_error: signup_error.message
                    });
                });
            });
        }).catch((errors) => {
            console.log("Form validation errors: ", JSON.stringify(errors, null, 4))
        });
    }

    render = () => {
        const vendor = this.props.user;
        const { processing, signup_error } = this.state;
        return (
            <Layout>
                <Helmet title={`Procurement ${this.props.name ? `| ${this.props.name}` : ''}`} />
                <Content>
                    <Row>
                        <Col className="start-banner d-sm-down-none" xs={0} sm={0} md={12} span={24}>
                            <img className="welcome-logo" alt="Heirs Procurement" src={logo} />
                        </Col>
                        <Col className="full-screen-height md:p-24" xs={24} sm={24} md={12} span={24}>
                            <Form
                                ref={this.signupForm}
                                className="login-form">
                                <Row>
                                    <Col xs={24}>
                                        <div className="welcome-msg">Let's get you started</div>
                                    </Col>
                                </Row>
                                {!!signup_error && (
                                    <Row className="mt-2">
                                        <Col xs={24}>
                                            <div className="text-center">
                                                <Alert type="error" message={signup_error} closable />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Form.Item style={{ marginBottom: 0, marginTop: 10 }}>
                                    <div className="custom-input floating-label">
                                        <Input readOnly placeholder="Company Name" value={vendor.name} />
                                        <label className="visible">Company Name</label>
                                    </div>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <div className="custom-input floating-label">
                                        <Input readOnly placeholder="Contact Person" value={vendor.contact_name} />
                                        <label className="visible">Contact Person</label>
                                    </div>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <div className="custom-input floating-label">
                                        <Input readOnly placeholder="Contact Person" value={vendor.email} />
                                        <label className="visible">Contact Email</label>
                                    </div>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <div className="custom-input floating-label">
                                        <Input readOnly placeholder="Contact Person" value={vendor.contact_phone} />
                                        <label className="visible">Contact Phone</label>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label=" "
                                    className="action-input mt-5"
                                    name="AgreeTerms"
                                    valuePropName="checked"
                                    initialValue={false}
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Please agree to terms of service')),
                                        }
                                    ]}>
                                    <Checkbox>
                                        <span className="terms">
                                            Accept <a href="/vendors/terms" target="_blank" rel="noreferrer">
                                                <b>Terms &amp; conditions</b>
                                            </a> and <a href="/vendors/privacy" target="_blank" rel="noreferrer">
                                                <b>Privacy policy</b>
                                            </a>
                                        </span>
                                    </Checkbox>
                                </Form.Item>
                                <div className="flex align-items-center" style={{ flexWrap: "wrap" }}>
                                    <Button
                                        type="danger"
                                        block
                                        htmlType="submit"
                                        loading={processing}
                                        className="flex flex-row items-center justify-between rounded-lg"
                                        style={{ height: '45px' }}
                                        onClick={this.handleSubmit}>
                                        <span>Get Started</span>
                                        <RightOutlined />
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user_data
    };
};

const mapDispatchToProps = {
    updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorSignup);