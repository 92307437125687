export const GET_CURRENCIES = [{
    "Id": 0,
    "Name": "Naira"
}]

export const GET_INVENTORIES = {
    "TotalCount": 3,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "f1c1c0ed-8224-46e2-8df5-278d80e908ee",
        "ProductName": "Peak Milk",
        "Stock": 200,
        "ReorderLevel": 180,
        "PictureUrl": "peakmilk-1644835246681.png",
        "CategoryId": 20,
        "CategoryName": "Provision",
        "CreatedDate": "2022-02-14T10:47:06.25793",
        "UnitOfMeasurement": "sachet"
    }, {
        "Id": "783add44-c1eb-46bb-b8c1-4490adfd7e21",
        "ProductName": "Loud Speaker",
        "Stock": 10,
        "ReorderLevel": 2,
        "PictureUrl": "rocket-1642342953566.png",
        "CategoryId": 19,
        "CategoryName": "PA Facilities",
        "CreatedDate": "2022-01-16T14:23:57.683285",
        "UnitOfMeasurement": "Item"
    }, {
        "Id": "5fbbf310-70b5-4eaf-a5ee-28558c8533f3",
        "ProductName": "Sony Sound Amplifier",
        "Stock": 10,
        "ReorderLevel": 5,
        "PictureUrl": null,
        "CategoryId": 19,
        "CategoryName": "PA Facilities",
        "CreatedDate": "2021-09-20T09:29:43.0719",
        "UnitOfMeasurement": null
    }]
}

export const GET_INVENTORY = {
    "Variations": [{
        "Id": "6d1762d2-24c7-4cb2-8f61-302713c6ba38",
        "Label": "Sony",
        "Value": "Subwoofer"
    }, {
        "Id": "b1d5ceac-ec7c-49fc-b5f8-45b8b34522e8",
        "Label": "LG",
        "Value": "Daleru"
    }],
    "ReorderLevel": 2,
    "Amount": 120000.00000000000000000000000,
    "Currency": 0,
    "Description": "Loud speaker noni",
    "Id": "783add44-c1eb-46bb-b8c1-4490adfd7e21",
    "ProductName": "Loud Speaker",
    "Stock": 10,
    "PictureUrl": "rocket-1642342953566.png",
    "CategoryId": 19,
    "CategoryName": "PA Facilities",
    "CreatedDate": "2022-01-16T14:23:57.683285",
    "UnitOfMeasurement": "Item"
}

export const GET_APPROVAL_SETTINGS = [{
    "Id": 10,
    "RoleId": 226,
    "RoleName": "Testing Role",
    "OrderBy": 1,
    "ApprovingUserId": 3,
    "ApprovingUserName": "Test User1"
}]

export const GET_MY_PENDING_REQUESTS = {
    "TotalCount": 1,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "bc64b7e0-9e3d-4d32-ae2e-33d7cb231184",
        "InventoryId": "783add44-c1eb-46bb-b8c1-4490adfd7e21",
        "ProductName": "Loud Speaker",
        "PictureUrl": "rocket-1642342953566.png",
        "InitiatingUserId": 3,
        "InitiatorName": "Test User1",
        "Quantity": 8,
        "CanEdit": true,
        "CreatedDate": "2022-01-22T14:06:51.21816",
        "DateTobeUsed": "2022-01-31T14:06:45.318",
        "UnitOfMeasurement": "Item",
        "InventoryStatus": 0,
        "InventoryStatusName": "Pending",
        "IsMyRequest": true,
        "NextInventoryApproval": {
            "ApprovalSettingId": 10,
            "ApprovalUserId": 3,
            "ApprovalUserName": null,
            "Status": 0,
            "StatusName": null,
            "ReasonOfRejection": null,
            "OrderBy": 1
        }
    }]
}

export const GET_MY_REQUESTS = {
    "TotalCount": 2,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "338eaa38-593b-4be5-ace5-c84fd1174001",
        "InventoryId": "5fbbf310-70b5-4eaf-a5ee-28558c8533f3",
        "ProductName": "Sony Sound Amplifier",
        "PictureUrl": null,
        "InitiatingUserId": 3,
        "InitiatorName": "Test User1",
        "Quantity": 3,
        "CreatedDate": "2022-02-06T20:57:18.017583",
        "DateTobeUsed": "2022-02-28T20:57:04.473",
        "UnitOfMeasurement": null,
        "InventoryStatus": 0,
        "InventoryStatusName": "Pending",
        "IsMyRequest": true,
        "NextInventoryApproval": null
    }, {
        "Id": "bc64b7e0-9e3d-4d32-ae2e-33d7cb231184",
        "InventoryId": "783add44-c1eb-46bb-b8c1-4490adfd7e21",
        "ProductName": "Loud Speaker",
        "PictureUrl": "rocket-1642342953566.png",
        "InitiatingUserId": 3,
        "InitiatorName": "Test User1",
        "Quantity": 8,
        "CreatedDate": "2022-01-22T14:06:51.21816",
        "DateTobeUsed": "2022-01-31T14:06:45.318",
        "UnitOfMeasurement": "Item",
        "InventoryStatus": 0,
        "InventoryStatusName": "Pending",
        "IsMyRequest": true,
        "NextInventoryApproval": {
            "ApprovalSettingId": 10,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "Status": 0,
            "StatusName": null,
            "ReasonOfRejection": null,
            "OrderBy": 1
        }
    }]
}

export const GET_ALL_REQUESTS = {
    "TotalCount": 2,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "338eaa38-593b-4be5-ace5-c84fd1174001",
        "InventoryId": "5fbbf310-70b5-4eaf-a5ee-28558c8533f3",
        "ProductName": "Sony Sound Amplifier",
        "PictureUrl": null,
        "InitiatingUserId": 3,
        "InitiatorName": "Test User1",
        "Quantity": 3,
        "CreatedDate": "2022-02-06T20:57:18.017583",
        "DateTobeUsed": "2022-02-28T20:57:04.473",
        "UnitOfMeasurement": null,
        "InventoryStatus": 0,
        "InventoryStatusName": "Pending",
        "IsMyRequest": true,
        "NextInventoryApproval": null
    }, {
        "Id": "bc64b7e0-9e3d-4d32-ae2e-33d7cb231184",
        "InventoryId": "783add44-c1eb-46bb-b8c1-4490adfd7e21",
        "ProductName": "Loud Speaker",
        "PictureUrl": "rocket-1642342953566.png",
        "InitiatingUserId": 3,
        "InitiatorName": "Test User1",
        "Quantity": 8,
        "CreatedDate": "2022-01-22T14:06:51.21816",
        "DateTobeUsed": "2022-01-31T14:06:45.318",
        "UnitOfMeasurement": "Item",
        "InventoryStatus": 0,
        "InventoryStatusName": "Pending",
        "IsMyRequest": true,
        "NextInventoryApproval": {
            "ApprovalSettingId": 10,
            "ApprovalUserId": 3,
            "ApprovalUserName": null,
            "Status": 0,
            "StatusName": null,
            "ReasonOfRejection": null,
            "OrderBy": 1
        }
    }]
}

export const GET_INVENTORY_HISTORIES = {
    "TotalCount": 1,
    "CurrentPage": 1,
    "ExpectedPageSize": 10,
    "Results": [
        {
            "Id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "UserId": 0,
            "InitiatorName": "string",
            "ProductName": "string",
            "Quantity": 0,
            "PictureUrl": "string",
            "CategoryName": "string",
            "CreatedDate": "2022-02-06T20:16:37.687Z",
            "UnitOfMeasurement": "string"
        }
    ]
}

export const GET_INVENTORY_SUMMARY = {
    "StockBelowReoderLevel": 29,
    "OutOfStock": 122,
    "InStockAboveReorderLevel": 118
}