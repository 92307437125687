import React, { Component } from "react";

class VendorIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    d="M23.0127 13.325C22.1973 12.4164 21.0014 11.8865 19.7239 11.8865H14.0161C14.0161 11.8865 13.9346 11.8108 13.9074 11.7855C13.0377 10.8013 11.733 10.2461 10.3469 10.2461H4.63908C3.2529 10.2461 1.97544 10.8013 1.0785 11.7855C0.208745 12.7698 -0.171777 14.0316 0.0728418 15.2934L0.996965 21.9054V21.9559C1.24158 23.142 2.35596 24 3.63342 24H20.5665C21.7896 24 22.8224 23.1925 23.067 22.0568L23.9096 16.631C24.1814 15.4196 23.8281 14.2335 23.0127 13.325ZM2.62776 21.6783L1.70364 15.0663V15.0158C1.54056 14.2082 1.78518 13.3754 2.35596 12.7445C2.92674 12.1136 3.76932 11.7351 4.66625 11.7351H10.374C11.271 11.7351 12.1135 12.0884 12.6843 12.7445C13.2551 13.3754 13.4997 14.2082 13.3366 15.0158L12.3853 21.6531C12.2766 22.1073 11.8417 22.4354 11.3525 22.4354H3.71496C3.14418 22.4606 2.73648 22.1325 2.62776 21.6783ZM22.3332 16.3534L21.4906 21.7792C21.4091 22.183 21.0557 22.4606 20.6209 22.4606H13.7987C13.8802 22.284 13.9346 22.1073 13.989 21.9307L14.9403 15.2934C15.049 14.6625 15.0218 14.0316 14.8587 13.4259H19.7783C20.5665 13.4259 21.3275 13.754 21.8168 14.3092C22.306 14.8644 22.4691 15.5963 22.3332 16.3534Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M17.1148 10.9779C19.262 10.9779 21.0287 9.33756 21.0287 7.34387V6.05681C21.0287 4.06311 19.262 2.42273 17.1148 2.42273C14.9676 2.42273 13.2009 4.06311 13.2009 6.05681V7.34387C13.2009 9.33756 14.9676 10.9779 17.1148 10.9779ZM14.8317 6.05681C14.8317 4.89592 15.8645 3.93693 17.1148 3.93693C18.3651 3.93693 19.3979 4.89592 19.3979 6.05681V7.34387C19.3979 8.50475 18.3651 9.46375 17.1148 9.46375C15.8645 9.46375 14.8317 8.50475 14.8317 7.34387V6.05681Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M7.46581 9.23659C9.77611 9.23659 11.6787 7.49526 11.6787 5.32492V3.91167C11.6787 1.76656 9.80329 0 7.46581 0C5.15552 0 3.25293 1.74133 3.25293 3.91167V5.32492C3.28011 7.47003 5.15552 9.23659 7.46581 9.23659ZM4.91091 3.91167C4.91091 2.59937 6.07965 1.5142 7.493 1.5142C8.90636 1.5142 10.0751 2.59937 10.0751 3.91167V5.32492C10.0751 6.63722 8.90636 7.72239 7.493 7.72239C6.07965 7.72239 4.91091 6.63722 4.91091 5.32492V3.91167Z"
                    fill="#5E5E5E"
                />
            </svg>
        )
    }
}

export default VendorIcon;
