import React, { Component } from 'react';
import { Layout } from "antd";

import { SideBar, MainContent } from '../../components/organisms';

class MainLayout extends Component {
    render = () => {
        return (
            <Layout className="main-shell">
                <SideBar />
                <Layout className="content-shell my-6 md:mr-5 rounded-lg">
                    <MainContent {...this.props} />
                </Layout>
            </Layout>
        );
    }
}

export default MainLayout;