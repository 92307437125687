import {
    START_LOAD_COUNTRIES, LOAD_COUNTRIES, LOAD_COUNTRIES_FAILED,
    START_LOAD_COMPANIES, LOAD_COMPANIES, LOAD_COMPANIES_FAILED,
    START_LOAD_VENDOR_CATEGORIES, LOAD_VENDOR_CATEGORIES, LOAD_VENDOR_CATEGORIES_FAILED,
    START_LOAD_VENDOR_TYPES, LOAD_VENDOR_TYPES, LOAD_VENDOR_TYPES_FAILED,
    START_LOAD_VENDOR_TYPE_QUESTIONS, LOAD_VENDOR_TYPE_QUESTIONS, LOAD_VENDOR_TYPE_QUESTIONS_FAILED,
    START_LOAD_VENDOR_DOCUMENT_TYPES, LOAD_VENDOR_DOCUMENT_TYPES, LOAD_VENDOR_DOCUMENT_TYPES_FAILED,
    START_LOAD_RESIDENCE_TYPES, LOAD_RESIDENCE_TYPES, LOAD_RESIDENCE_TYPES_FAILED,
    START_LOAD_COMPANY_TYPES, LOAD_COMPANY_TYPES, LOAD_COMPANY_TYPES_FAILED,
    START_LOAD_QUESTION_CATEGORIES, LOAD_QUESTION_CATEGORIES, LOAD_QUESTION_CATEGORIES_FAILED,
    START_LOAD_USERS, LOAD_USERS, LOAD_USERS_FAILED,
    START_LOAD_BANKS, LOAD_BANKS, LOAD_BANKS_FAILED,
    START_LOAD_COMPANY_ROLES, LOAD_COMPANY_ROLES, LOAD_COMPANY_ROLES_FAILED,
    START_LOAD_INVENTORY_CATEGORIES, LOAD_INVENTORY_CATEGORIES, LOAD_INVENTORY_CATEGORIES_FAILED,
    START_LOAD_CURRENCIES, LOAD_CURRENCIES, LOAD_CURRENCIES_FAILED,
    START_LOAD_CONTRACT_TEMPLATES, LOAD_CONTRACT_TEMPLATES, LOAD_CONTRACT_TEMPLATES_FAILED,
    START_LOAD_CONTRACT_TEMPLATE_TAGS, LOAD_CONTRACT_TEMPLATE_TAGS, LOAD_CONTRACT_TEMPLATE_TAGS_FAILED,
    START_LOAD_CONTRACT_TYPES, LOAD_CONTRACT_TYPES, LOAD_CONTRACT_TYPES_FAILED
} from '../types/config_types';

const initialState = {
    loading_countries: false,
    countries: [],
    load_countries_error: '',
    loading_companies: false,
    group_companies: [],
    load_companies_error: '',
    loading_vendor_categories: false,
    vendor_categories: [],
    load_vendor_categories_error: '',
    loading_vendor_types: false,
    vendor_types: [],
    load_vendor_types_error: '',
    loading_vendor_type_questions: false,
    vendor_type_questions: [],
    load_vendor_type_questions_error: '',
    loading_vendor_document_types: false,
    vendor_document_types: [],
    load_vendor_document_types_error: '',
    loading_residence_types: false,
    residence_types: [],
    load_residence_types_error: '',
    loading_company_types: false,
    company_types: [],
    load_company_types_error: '',
    loading_question_categories: false,
    question_categories: [],
    load_question_categories_failed: '',
    loading_users: false,
    users: [],
    load_users_error: '',
    loading_banks: false,
    banks: [],
    load_banks_error: '',
    loading_inventory_categories: false,
    inventory_categories: [],
    load_inventory_categories_error: '',
    loading_currencies: false,
    currencies: [],
    load_currencies_error: '',
    loading_contract_templates: false,
    contract_templates: [],
    contract_templates_error: null,
    loading_contract_template_tags: false,
    contract_template_tags: [],
    contract_template_tags_error: null,
    loading_contract_types: false,
    contract_types: [],
    contract_types_error: null
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOAD_COUNTRIES: {
            return {
                ...state,
                loading_countries: true
            }
        }
        case LOAD_COUNTRIES: {
            return {
                ...state,
                loading_countries: false,
                countries: action.countries
            }
        }
        case LOAD_COUNTRIES_FAILED: {
            return {
                ...state,
                loading_countries: false,
                load_countries_error: action.error_message
            }
        }
        case START_LOAD_COMPANIES: {
            return {
                ...state,
                loading_companies: true
            }
        }
        case LOAD_COMPANIES: {
            return {
                ...state,
                loading_companies: false,
                group_companies: action.group_companies
            }
        }
        case LOAD_COMPANIES_FAILED: {
            return {
                ...state,
                loading_companies: false,
                load_companies_error: action.error_message
            }
        }
        case START_LOAD_VENDOR_CATEGORIES: {
            return {
                ...state,
                loading_vendor_categories: true
            }
        }
        case LOAD_VENDOR_CATEGORIES: {
            return {
                ...state,
                loading_vendor_categories: false,
                vendor_categories: action.vendor_categories
            }
        }
        case LOAD_VENDOR_CATEGORIES_FAILED: {
            return {
                ...state,
                loading_vendor_categories: false,
                load_vendor_categories_error: action.error_message
            }
        }
        case START_LOAD_VENDOR_TYPES: {
            return {
                ...state,
                loading_vendor_types: true
            }
        }
        case LOAD_VENDOR_TYPES: {
            return {
                ...state,
                loading_vendor_types: false,
                vendor_types: action.vendor_types
            }
        }
        case LOAD_VENDOR_TYPES_FAILED: {
            return {
                ...state,
                loading_vendor_types: false,
                load_vendor_types_error: action.error_message
            }
        }
        case START_LOAD_VENDOR_TYPE_QUESTIONS: {
            return {
                ...state,
                loading_vendor_type_questions: true
            }
        }
        case LOAD_VENDOR_TYPE_QUESTIONS: {
            let vendor_type_questions = action.vendor_type_questions;
            vendor_type_questions = vendor_type_questions.map((question) => {
                if (
                    question.QuestionType === 1 &&
                    question.VendorTypeQuestionOptions
                ) {
                    question.VendorTypeQuestionOptions = question.VendorTypeQuestionOptions.sort(
                        (option1, option2) =>
                            option1.OrderBy - option2.OrderBy
                    );
                }

                return question;
            });
            return {
                ...state,
                vendor_type_questions,
                loading_vendor_type_questions: false
            }
        }
        case LOAD_VENDOR_TYPE_QUESTIONS_FAILED: {
            return {
                ...state,
                loading_vendor_type_questions: false,
                load_vendor_type_questions_error: action.error_message
            }
        }
        case START_LOAD_VENDOR_DOCUMENT_TYPES: {
            return {
                ...state,
                loading_vendor_document_types: true
            }
        }
        case LOAD_VENDOR_DOCUMENT_TYPES: {
            return {
                ...state,
                vendor_document_types: action.vendor_document_types,
                loading_vendor_document_types: false
            }
        }
        case LOAD_VENDOR_DOCUMENT_TYPES_FAILED: {
            return {
                ...state,
                loading_vendor_document_types: false,
                load_vendor_document_types_error: action.error_message
            }
        }
        case START_LOAD_RESIDENCE_TYPES: {
            return {
                ...state,
                loading_residence_types: true
            }
        }
        case LOAD_RESIDENCE_TYPES: {
            return {
                ...state,
                loading_residence_types: false,
                residence_types: action.residence_types
            }
        }
        case LOAD_RESIDENCE_TYPES_FAILED: {
            return {
                ...state,
                loading_residence_types: false,
                load_residence_types_error: action.error_message
            }
        }
        case START_LOAD_COMPANY_TYPES: {
            return {
                ...state,
                loading_company_types: true
            }
        }
        case LOAD_COMPANY_TYPES: {
            return {
                ...state,
                loading_company_types: false,
                company_types: action.company_types
            }
        }
        case LOAD_COMPANY_TYPES_FAILED: {
            return {
                ...state,
                loading_company_types: false,
                load_company_types_error: action.error_message
            }
        }
        case START_LOAD_QUESTION_CATEGORIES: {
            return {
                ...state,
                loading_question_categories: true
            }
        }
        case LOAD_QUESTION_CATEGORIES: {
            return {
                ...state,
                loading_question_categories: false,
                question_categories: action.question_categories
            }
        }
        case LOAD_QUESTION_CATEGORIES_FAILED: {
            return {
                ...state,
                loading_question_categories: false,
                load_question_categories_error: action.error_message
            }
        }
        case START_LOAD_USERS: {
            return {
                ...state,
                loading_users: true
            }
        }
        case LOAD_USERS: {
            return {
                ...state,
                loading_users: false,
                users: action.users
            }
        }
        case LOAD_USERS_FAILED: {
            return {
                ...state,
                loading_users: false,
                load_users_error: action.error_message
            }
        }
        case START_LOAD_BANKS: {
            return {
                ...state,
                loading_banks: true
            }
        }
        case LOAD_BANKS: {
            return {
                ...state,
                loading_banks: false,
                banks: action.banks
            }
        }
        case LOAD_BANKS_FAILED: {
            return {
                ...state,
                loading_banks: false,
                load_banks_error: action.error_message
            }
        }
        case START_LOAD_COMPANY_ROLES: {
            return {
                ...state,
                loading_company_roles: true
            }
        }
        case LOAD_COMPANY_ROLES: {
            return {
                ...state,
                loading_company_roles: false,
                company_roles: action.company_roles
            }
        }
        case LOAD_COMPANY_ROLES_FAILED: {
            return {
                ...state,
                loading_company_roles: false,
                load_company_roles_error: action.error_message
            }
        }
        case START_LOAD_INVENTORY_CATEGORIES: {
            return {
                ...state,
                loading_inventory_categories: true
            }
        }
        case LOAD_INVENTORY_CATEGORIES: {
            return {
                ...state,
                loading_inventory_categories: false,
                inventory_categories: action.inventory_categories
            }
        }
        case LOAD_INVENTORY_CATEGORIES_FAILED: {
            return {
                ...state,
                loading_inventory_categories: false,
                load_inventory_categories_error: action.error_message
            }
        }
        case START_LOAD_CURRENCIES: {
            return {
                ...state,
                loading_currencies: true
            }
        }
        case LOAD_CURRENCIES: {
            return {
                ...state,
                loading_currencies: false,
                currencies: action.currencies
            }
        }
        case LOAD_CURRENCIES_FAILED: {
            return {
                ...state,
                loading_currencies: false,
                load_currencies_error: action.error_message
            }
        }
        case START_LOAD_CONTRACT_TEMPLATES: {
            return {
                ...state,
                loading_contract_templates: true,
                contract_templates_error: null
            }
        }
        case LOAD_CONTRACT_TEMPLATES: {
            return {
                ...state,
                loading_contract_templates: false,
                contract_templates: action.templates
            }
        }
        case LOAD_CONTRACT_TEMPLATES_FAILED: {
            return {
                ...state,
                loading_contract_templates: false,
                contract_templates_error: action.error_message
            }
        }
        case START_LOAD_CONTRACT_TEMPLATE_TAGS: {
            return {
                ...state,
                loading_contract_template_tags: true,
                contract_template_tags_error: null
            }
        }
        case LOAD_CONTRACT_TEMPLATE_TAGS: {
            return {
                ...state,
                loading_contract_template_tags: false,
                contract_template_tags: action.template_tags
            }
        }
        case LOAD_CONTRACT_TEMPLATE_TAGS_FAILED: {
            return {
                ...state,
                loading_contract_template_tags: false,
                contract_template_tags_error: action.error_message
            }
        }
        case START_LOAD_CONTRACT_TYPES: {
            return {
                ...state,
                loading_contract_types: true,
                contract_types_error: null
            }
        }
        case LOAD_CONTRACT_TYPES: {
            return {
                ...state,
                loading_contract_types: false,
                contract_types: action.contract_types
            }
        }
        case LOAD_CONTRACT_TYPES_FAILED: {
            return {
                ...state,
                loading_contract_types: false,
                contract_types_error: action.error_message
            }
        }
        default: {
            return state;
        }
    }
};

export default configReducer;