import { Component } from 'react';
import { Layout } from "antd";
import { Menu } from '../molecules';

class SideBar extends Component {
    render = () => {
        return (
            <Layout.Sider
                className="sidebar"
                width={300}
                breakpoint="lg"
                theme="light"
                collapsedWidth={0}
                trigger={null}>
                <Menu />
            </Layout.Sider>
        );
    }
}

export default SideBar;