import { VIEWED_VENDOR_TOUR } from '../types/tour_types';

const initialState = {
    viewed_vendor_tour: false
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEWED_VENDOR_TOUR: {
            return {
                ...state,
                viewed_vendor_tour: true
            }
        }
        default: {
            return state;
        }
    }
};

export default configReducer;