export const START_LOAD_COUNTRIES = 'START_LOAD_COUNTRIES';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const LOAD_COUNTRIES_FAILED = 'LOAD_COUNTRIES_FAILED';

export const START_LOAD_COMPANIES = 'START_LOAD_COMPANIES';
export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_COMPANIES_FAILED = 'LOAD_COMPANIES_FAILED';

export const START_LOAD_VENDOR_CATEGORIES = 'START_LOAD_VENDOR_CATEGORIES';
export const LOAD_VENDOR_CATEGORIES = 'LOAD_VENDOR_CATEGORIES';
export const LOAD_VENDOR_CATEGORIES_FAILED = 'LOAD_VENDOR_CATEGORIES_FAILED';

export const START_LOAD_VENDOR_TYPES = 'START_LOAD_VENDOR_TYPES';
export const LOAD_VENDOR_TYPES = 'LOAD_VENDOR_TYPES';
export const LOAD_VENDOR_TYPES_FAILED = 'LOAD_VENDOR_TYPES_FAILED';

export const START_LOAD_VENDOR_TYPE_QUESTIONS = 'START_LOAD_VENDOR_TYPE_QUESTIONS';
export const LOAD_VENDOR_TYPE_QUESTIONS = 'LOAD_VENDOR_TYPE_QUESTIONS';
export const LOAD_VENDOR_TYPE_QUESTIONS_FAILED = 'LOAD_VENDOR_TYPE_QUESTIONS_FAILED';

export const START_LOAD_VENDOR_DOCUMENT_TYPES = 'START_LOAD_VENDOR_DOCUMENT_TYPES';
export const LOAD_VENDOR_DOCUMENT_TYPES = 'LOAD_VENDOR_DOCUMENT_TYPES';
export const LOAD_VENDOR_DOCUMENT_TYPES_FAILED = 'LOAD_VENDOR_DOCUMENT_TYPES_FAILED';

export const START_LOAD_RESIDENCE_TYPES = 'START_LOAD_RESIDENCE_TYPES';
export const LOAD_RESIDENCE_TYPES = 'LOAD_RESIDENCE_TYPES';
export const LOAD_RESIDENCE_TYPES_FAILED = 'LOAD_RESIDENCE_TYPES_FAILED';

export const START_LOAD_COMPANY_TYPES = 'START_LOAD_COMPANY_TYPES';
export const LOAD_COMPANY_TYPES = 'LOAD_COMPANY_TYPES';
export const LOAD_COMPANY_TYPES_FAILED = 'LOAD_COMPANY_TYPES_FAILED';

export const START_LOAD_QUESTION_CATEGORIES = 'START_LOAD_QUESTION_CATEGORIES';
export const LOAD_QUESTION_CATEGORIES = 'LOAD_QUESTION_CATEGORIES';
export const LOAD_QUESTION_CATEGORIES_FAILED = 'LOAD_QUESTION_CATEGORIES_FAILED';

export const START_LOAD_USERS = 'START_LOAD_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_FAILED = 'LOAD_USERS_FAILED';

export const START_LOAD_BANKS = 'START_LOAD_BANKS';
export const LOAD_BANKS = 'LOAD_BANKS';
export const LOAD_BANKS_FAILED = 'LOAD_BANKS_FAILED';

export const START_LOAD_COMPANY_ROLES = 'START_LOAD_COMPANY_ROLES';
export const LOAD_COMPANY_ROLES = 'LOAD_COMPANY_ROLES';
export const LOAD_COMPANY_ROLES_FAILED = 'LOAD_COMPANY_ROLES_FAILED';

export const START_LOAD_INVENTORY_CATEGORIES = 'START_LOAD_INVENTORY_CATEGORIES';
export const LOAD_INVENTORY_CATEGORIES = 'LOAD_INVENTORY_CATEGORIES';
export const LOAD_INVENTORY_CATEGORIES_FAILED = 'LOAD_INVENTORY_CATEGORIES_FAILED';

export const START_LOAD_CURRENCIES = 'START_LOAD_CURRENCIES';
export const LOAD_CURRENCIES = 'LOAD_CURRENCIES';
export const LOAD_CURRENCIES_FAILED = 'LOAD_CURRENCIES_FAILED';

export const START_LOAD_CONTRACT_TEMPLATE_TAGS = 'START_LOAD_CONTRACT_TEMPLATE_TAGS';
export const LOAD_CONTRACT_TEMPLATE_TAGS = 'LOAD_CONTRACT_TEMPLATE_TAGS';
export const LOAD_CONTRACT_TEMPLATE_TAGS_FAILED = 'LOAD_CONTRACT_TEMPLATE_TAGS_FAILED';

export const START_LOAD_CONTRACT_TEMPLATES = 'START_LOAD_CONTRACT_TEMPLATES';
export const LOAD_CONTRACT_TEMPLATES = 'LOAD_CONTRACT_TEMPLATES';
export const LOAD_CONTRACT_TEMPLATES_FAILED = 'LOAD_CONTRACT_TEMPLATES_FAILED';

export const START_LOAD_CONTRACT_TYPES = 'START_LOAD_CONTRACT_TYPES';
export const LOAD_CONTRACT_TYPES = 'LOAD_CONTRACT_TYPES';
export const LOAD_CONTRACT_TYPES_FAILED = 'LOAD_CONTRACT_TYPES_FAILED';