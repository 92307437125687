import React, { Component } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Row, Col } from "antd";
import { setVendorTourViewed } from '../../redux/actions/tour_actions';
import tips from "../../views/assets/images/lightbulb.png";

class VendorTour extends Component {
    state = {
        current_page: 0,
        pages: [
            {
                title: 'Contact Management',
                description: 'Add key contacts in your business'
            },
            {
                title: 'Detailed Overview',
                description: 'Access your dashboard for business intelligence on procurements so far'
            },
            {
                title: 'Profile',
                description: 'Update your KYC information'
            },
            {
                title: 'Optimized Processing',
                description: 'Respond and send RFQ & RFP'
            },
            {
                title: 'Keep Informed',
                description: 'Sign and get reminders on key contract milestones'
            }
        ]
    }

    handleNextPage = () => {
        this.setState({
            current_page: this.state.current_page + 1
        });
    }

    handlePrevPage = () => {
        this.setState({
            current_page: this.state.current_page - 1
        });
    }

    handleTourDone = () => {
        this.props.setVendorTourViewed();
        this.props.onClose();
    }

    render = () => {
        const { current_page, pages } = this.state;
        const page_data = pages[current_page];
        return (
            <Modal
                bodyStyle={{ padding: 0 }}
                closable={false}
                width={420}
                visible={true}
                footer={null}>
                <div className="success-modal pt-10 pb-5 px-6">
                    <Row wrap={false}>
                        <Col flex="none" className="mr-5">
                            <div style={{ padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '50%' }}>
                                <img style={{ height: 35 }} alt="Tips" src={tips} />
                            </div>
                        </Col>
                        <Col flex="auto">
                            <h2 className="">{page_data.title}</h2>
                            <p className="pt-2 pb-5 description">{page_data.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="full-width">
                            <div className="flex justify-content-end full-width font-semibold">
                                <Button type="text" disabled={current_page <= 0} className="p-1" onClick={this.handlePrevPage}>
                                    <span className="text-xs font-semibold">PREVIOUS</span>
                                </Button>
                                <Button type="text" disabled={current_page >= pages.length - 1} className="p-1" onClick={this.handleNextPage}>
                                    <span className="text-xs font-semibold">NEXT</span>
                                </Button>
                                <Button type="text" className="p-1" onClick={this.handleTourDone}>
                                    {current_page < pages.length - 1 && (
                                        <span className="text-xs font-semibold">SKIP TIPS</span>
                                    )}
                                    {current_page >= pages.length - 1 && (
                                        <span className="text-xs font-semibold">DONE</span>
                                    )}
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tour: state.tour
    };
};

const mapDispatchToProps = {
    setVendorTourViewed
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorTour);