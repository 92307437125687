import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { AuditMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Audits`;

export const getAudits = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(AuditMock.GET_AUDITS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetAudits?${Util.objectToQueryString(params)}`, 'get');
}