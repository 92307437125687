export const GET_PROCUREMENT_SUMMARIES = {
    "Completed": 670,
    "UnCompleted": 247
}

export const GET_PROCUREMENTS = {
    "TotalCount": 5,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "56b116af-1e5a-43fb-9d76-79c9d479d485",
        "Name": "Sample Procurement",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "Status": "Pending",
        "LiteProcurementTasks": [{
            "Id": "8163aa27-61fa-4e23-a586-245c7177ccfa",
            "Status": 0
        }, {
            "Id": "e8a77c73-269f-43bb-8256-d6851538b140",
            "Status": 0
        }]
    }, {
        "Id": "760c993d-ccac-42e8-82f6-dfbe651b8289",
        "Name": "Neque porro quisquam est qui dolorem ipsum",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "Status": "Pending",
        "LiteProcurementTasks": [{
            "Id": "e00f19a4-b5a9-4d59-a375-4b8361555234",
            "Status": 0
        }]
    }, {
        "Id": "bf4a676f-70f6-4bc5-927c-464f9f5aee93",
        "Name": "Lorem Ipsum",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "Status": "Pending",
        "LiteProcurementTasks": [{
            "Id": "3093d932-553b-4f9b-8c52-05a74857e8b4",
            "Status": 0
        }, {
            "Id": "a17c76f4-0063-424d-b6ac-fb3b7cbd279f",
            "Status": 0
        }]
    }, {
        "Id": "c610cda4-c442-47e5-b3a8-07fe3c310aa5",
        "Name": "Sample Procurement",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "Status": "Pending",
        "LiteProcurementTasks": [{
            "Id": "c1518f1c-94fa-47bd-9727-39c98070b3fd",
            "Status": 0
        }]
    }, {
        "Id": "aeecbdbc-9d7a-4549-a419-01d62747f5dc",
        "Name": "Development of test mobile app",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "Status": "Pending",
        "LiteProcurementTasks": [{
            "Id": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
            "Status": 0
        }]
    }]
}

export const GET_PROCUREMENT_BY_ID = {
    "Id": "aeecbdbc-9d7a-4549-a419-01d62747f5dc",
    "Name": "Development of test mobile app",
    "ProjectCommiteeId": "d1325569-1e68-4a13-9151-f1916b951722",
    "ProjectCommitee": {
        "Id": "d1325569-1e68-4a13-9151-f1916b951722",
        "Name": "Frontend Vendor Selection Committee",
        "Description": "In charge of selecting client-side development vendors",
        "TotalMembersCount": 4,
        "TwoCommitteeMembers": [{
            "MemberId": 3,
            "Name": "Test User1",
            "Initials": "T U"
        }, {
            "MemberId": 1327,
            "Name": "OOO Dmed",
            "Initials": "O D"
        }]
    },
    "InitiatorId": 3,
    "InitiatorName": "Test User1",
    "CompanyId": 19,
    "CompanyName": "Test Company",
    "Description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "Tasks": [
        {
            "Id": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
            "Name": "Develop mobile app",
            "Description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "Status": 1,
            "ApprovingAdminId": 3,
            "ApprovingAdminName": "Test User1",
            "StatusName": "Approved",
            "RFQSubmissionDate": "2021-06-01T18:22:40.026",
            "Vendors": [
                {
                    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
                    "VendorRFQSubmissionId": "2b9a769c-d2c1-485d-a6b8-6482294e7b6b",
                    "Surname": "Clerk",
                    "Firstname": "Lambert",
                    "Picture": null,
                    "VendorType": 1,
                    "VendorTypeName": "Business Name",
                    "Recommendation": 1,
                    "RecommendationName": "Recommended",
                    "HasBeenRecommended": true,
                    "CurrentRating": 0,
                    "HasSubmittedRFQ": true,
                    "SupportingDocumentUrl": "sample-1624963827454.pdf",
                    "Comment": "Some creative way to deliver the task on time. I know it sounds too good to be true, but I'm telling you I can make it happen",
                    "StatusUpdateDate": "2021-08-03T04:24:28.766263",
                    "ProposedDeliveryDate": "2021-07-15T10:50:18.169"
                }
            ],
            "HasBeenRecommended": true,
            "CommitteeRecommendation": {
                "RecommendedVendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
                "SupportingDocumentUrl": "",
                "Comments": "",
                "ApprovingAdminId": 3,
                "ApprovingAdminName": "Test User1"
            }
        }
    ]
}

export const GET_COMMITTEES = {
    "TotalCount": 10,
    "CurrentPage": 0,
    "ExpectedPageSize": 5,
    "Results": [{
        "Id": "3b8862a3-6fde-4906-bfaf-ca6396ce36ee",
        "Name": "Backend Dev Committee",
        "Description": "Review and select backend engineers for applicable projects",
        "TotalMembersCount": 2,
        "TwoCommitteeMembers": [{
            "MemberId": 3,
            "Name": "Test User1",
            "Initials": "T U"
        }, {
            "MemberId": 1327,
            "Name": "OOO Dmed",
            "Initials": "O D"
        }]
    }, {
        "Id": "d1325569-1e68-4a13-9151-f1916b951722",
        "Name": "Frontend Vendor Selection Committee",
        "Description": "In charge of selecting client-side development vendors",
        "TotalMembersCount": 4,
        "TwoCommitteeMembers": [{
            "MemberId": 3,
            "Name": "Test User1",
            "Initials": "T U"
        }, {
            "MemberId": 952,
            "Name": "TEST USER2",
            "Initials": "T U"
        }]
    }]
}

export const GET_COMMITTEE_MEMBERS = [
    {
        "MemberId": 3,
        "Name": "Test User1",
        "Initials": "T U"
    },
    {
        "MemberId": 952,
        "Name": "TEST USER2",
        "Initials": "T U"
    },
    {
        "MemberId": 988,
        "Name": "Bolanle Uni-son",
        "Initials": "B U"
    },
    {
        "MemberId": 1327,
        "Name": "OOO Dmed",
        "Initials": "O D"
    },
    {
        "MemberId": 1793,
        "Name": "DAVID ADEFEMI",
        "Initials": "D A"
    },
    {
        "MemberId": 1916,
        "Name": "Alade Grace",
        "Initials": "A G"
    }
]

export const GET_VENDOR_PROCUREMENTS = {
    "TotalCount": 6,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "TaskId": "e8a77c73-269f-43bb-8256-d6851538b140",
        "ProcurementId": "56b116af-1e5a-43fb-9d76-79c9d479d485",
        "TaskName": "Sample Task II",
        "ProcurementName": "Sample Procurement",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-08-15T07:48:47.636",
        "HasVendorSubmitted": false,
        "Status": 0,
        "StatusName": "Pending"
    }, {
        "TaskId": "8163aa27-61fa-4e23-a586-245c7177ccfa",
        "ProcurementId": "56b116af-1e5a-43fb-9d76-79c9d479d485",
        "TaskName": "Sample Task",
        "ProcurementName": "Sample Procurement",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-08-15T07:47:44.906",
        "HasVendorSubmitted": true,
        "Status": 0,
        "StatusName": "Pending"
    }, {
        "TaskId": "e00f19a4-b5a9-4d59-a375-4b8361555234",
        "ProcurementId": "760c993d-ccac-42e8-82f6-dfbe651b8289",
        "TaskName": "Where can I get some?",
        "ProcurementName": "Neque porro quisquam est qui dolorem ipsum",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-08-01T08:34:02.746",
        "HasVendorSubmitted": true,
        "Status": 0,
        "StatusName": "Pending"
    }, {
        "TaskId": "3093d932-553b-4f9b-8c52-05a74857e8b4",
        "ProcurementId": "bf4a676f-70f6-4bc5-927c-464f9f5aee93",
        "TaskName": "Why do we use it?",
        "ProcurementName": "Lorem Ipsum",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-06-30T15:55:13.227",
        "HasVendorSubmitted": true,
        "Status": 0,
        "StatusName": "Pending"
    }, {
        "TaskId": "a17c76f4-0063-424d-b6ac-fb3b7cbd279f",
        "ProcurementId": "bf4a676f-70f6-4bc5-927c-464f9f5aee93",
        "TaskName": "Where does it come from?",
        "ProcurementName": "Lorem Ipsum",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-06-30T15:54:28.199",
        "HasVendorSubmitted": true,
        "Status": 0,
        "StatusName": "Pending"
    }, {
        "TaskId": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
        "ProcurementId": "aeecbdbc-9d7a-4549-a419-01d62747f5dc",
        "TaskName": "Develop mobile app",
        "ProcurementName": "Development of test mobile app",
        "InitiatorId": 3,
        "InitiatorName": "Test User1",
        "CompanyId": 19,
        "CompanyName": "Test Company",
        "RFQSubmissionDate": "2021-06-01T18:22:40.026",
        "HasVendorSubmitted": true,
        "Status": 0,
        "StatusName": "Pending"
    }]
}

export const GET_VENDOR_PROCUREMENT_BY_ID = {
    "StatusUpdateDate": null,
    "DeclinedReason": null,
    "SupportingDocumentUrl": "sample-1626340119409.pdf",
    "ProcurementDescription": "Can you help translate this site into a foreign language? Please send us your quote with details if you can help.",
    "TaskDescription": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    "ProposedDeliveryDate": "2021-08-15T09:08:04.243",
    "TaskId": "e00f19a4-b5a9-4d59-a375-4b8361555234",
    "ProcurementId": "760c993d-ccac-42e8-82f6-dfbe651b8289",
    "TaskName": "Where can I get some?",
    "ProcurementName": "Neque porro quisquam est qui dolorem ipsum",
    "InitiatorId": 3,
    "InitiatorName": "Test User1",
    "CompanyId": 19,
    "CompanyName": null,
    "RFQSubmissionDate": "2021-08-01T08:34:02.746",
    "HasVendorSubmitted": true,
    "Status": 0,
    "StatusName": "Pending"
}

export const GET_APPROVED_PROJECT_TASKS = [{
    "TaskId": "e00f19a4-b5a9-4d59-a375-4b8361555234",
    "ProcurementId": "760c993d-ccac-42e8-82f6-dfbe651b8289",
    "TaskName": "Where can I get some?",
    "ProcurementName": "Neque porro quisquam est qui dolorem ipsum",
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "VendorName": "Lambert Clerk"
}, {
    "TaskId": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
    "ProcurementId": "aeecbdbc-9d7a-4549-a419-01d62747f5dc",
    "TaskName": "Develop mobile app",
    "ProcurementName": "Development of test mobile app",
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "VendorName": "Lambert Clerk"
}, {
    "TaskId": "a17c76f4-0063-424d-b6ac-fb3b7cbd279f",
    "ProcurementId": "bf4a676f-70f6-4bc5-927c-464f9f5aee93",
    "TaskName": "Where does it come from?",
    "ProcurementName": "Lorem Ipsum",
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "VendorName": "Lambert Clerk"
}, {
    "TaskId": "3093d932-553b-4f9b-8c52-05a74857e8b4",
    "ProcurementId": "bf4a676f-70f6-4bc5-927c-464f9f5aee93",
    "TaskName": "Why do we use it?",
    "ProcurementName": "Lorem Ipsum",
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "VendorName": "Lambert Clerk"
}]