import React, { Component } from "react";

class ProcurementIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    d="M22.4589 17.7548H21.6758V16.7226C21.6758 14.9161 20.2358 13.471 18.4926 13.471H12.7326V10.6581C15.2842 10.2968 17.2547 8.07742 17.2547 5.36774C17.2547 2.4 14.9053 0 12 0C9.09474 0 6.74526 2.4 6.74526 5.36774C6.74526 8.07742 8.71579 10.2968 11.2674 10.6581V13.471H5.53263C3.76421 13.471 2.34947 14.9419 2.34947 16.7226V17.7548H1.54105C0.682105 17.7548 0 18.4516 0 19.329V22.4258C0 23.3032 0.682105 24 1.54105 24H4.57263C5.43158 24 6.11368 23.3032 6.11368 22.4258V19.329C6.11368 18.4516 5.43158 17.7548 4.57263 17.7548H3.78947V16.7226C3.78947 15.7419 4.57263 14.9419 5.53263 14.9419H11.2674V17.7548H10.4842C9.62526 17.7548 8.94316 18.4516 8.94316 19.329V22.4258C8.94316 23.3032 9.62526 24 10.4842 24H13.5158C14.3747 24 15.0568 23.3032 15.0568 22.4258V19.329C15.0568 18.4516 14.3747 17.7548 13.5158 17.7548H12.7326V14.9419H18.4674C19.4274 14.9419 20.2105 15.7419 20.2105 16.7226V17.7548H19.4274C18.5684 17.7548 17.8863 18.4516 17.8863 19.329V22.4258C17.8863 23.3032 18.5684 24 19.4274 24H22.4589C23.3179 24 24 23.3032 24 22.4258V19.329C24 18.4516 23.3179 17.7548 22.4589 17.7548ZM8.18526 5.36774C8.18526 3.22581 9.90316 1.47097 12 1.47097C14.0968 1.47097 15.8147 3.22581 15.8147 5.36774C15.8147 7.50968 14.0968 9.26452 12 9.26452C9.90316 9.26452 8.18526 7.50968 8.18526 5.36774ZM4.67368 19.329V22.4258C4.67368 22.4774 4.62316 22.529 4.57263 22.529L1.44 22.4258L1.54105 19.2258L4.67368 19.329ZM13.6168 19.329V22.4258C13.6168 22.4774 13.5663 22.529 13.5158 22.529L10.3832 22.4258L10.4842 19.2258L13.6168 19.329ZM22.56 22.4258C22.56 22.4774 22.5095 22.529 22.4589 22.529L19.3263 22.4258L19.4274 19.2258L22.56 19.329V22.4258Z"
                />
                <path
                    className="b"
                    d="M14.0211 5.00638C14.299 4.6967 14.2738 4.23219 13.9959 3.97412C13.718 3.69025 13.238 3.71606 12.9854 3.99993L11.4948 5.65154L10.9643 5.05799C10.6864 4.74831 10.2317 4.74831 9.95377 5.03219C9.65062 5.31606 9.65062 5.78057 9.92851 6.06444L10.9896 7.22573C11.1159 7.38057 11.318 7.45799 11.5201 7.45799C11.7222 7.45799 11.899 7.38057 12.0506 7.22573L14.0211 5.00638Z"
                    fill="#5E5E5E"
                />
            </svg>
        )
    }
}

export default ProcurementIcon;
