import { SET_USER_DATA, UPDATE_USER_DATA, CLEAR_USER_DATA } from '../types/user_types';

export const setUserData = (user_data) => {
    return dispatch => {
        dispatch({
            type: SET_USER_DATA,
            user_data
        })
    };
};

export const updateUserData = (user_data) => {
    return dispatch => {
        dispatch({
            type: UPDATE_USER_DATA,
            user_data
        })
    };
};

export const clearUserData = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_USER_DATA
        })
    };
};