import React, { Component } from "react";

class InventoryIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    d="M11.664 0L0 7.128V24H1.416V7.92L11.664 1.656L22.584 7.944V24H24V7.104L11.664 0Z"
                    fill="#5E5E5E"
                />

                <path
                    className="b"
                    d="M16.3681 7.99194H7.63213V15.2639H3.98413V23.9999H11.2801H12.7201H20.0161V15.2639H16.3681V7.99194ZM9.04813 9.43194H10.5601V11.1359H13.4161V9.43194H14.9281V15.2639H12.7201H11.2801H9.04813V9.43194ZM5.40013 22.5839V16.6799H6.91213V18.3839H9.76813V16.7279H11.2801V22.5599H5.40013V22.5839ZM18.6001 22.5839H12.7201V16.7279H14.2321V18.3839H17.0881V16.6799H18.6001V22.5839Z"
                    fill="#5E5E5E"
                />
            </svg>
        )
    }
}

export default InventoryIcon;
