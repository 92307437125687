export const GET_EMAILS = {
    "TotalCount": 369,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "Id": "9611b2a9-496c-4cdc-b985-a255646229e1",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-12-05T06:31:14.45351",
        "Emailaddresses": "test.user1@heirsholdings.com",
        "Subject": "New Assigned Lopp project as Committee Member"
    }, {
        "Id": "665e9796-acfc-477c-9acc-d784e5604ca5",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-12-05T06:31:08.157262",
        "Emailaddresses": "ckdaf2000@yahoo.co.uk",
        "Subject": "Test Company's Proposal Request on loo Task"
    }, {
        "Id": "51700c18-7e26-4234-9ef3-9e3a9e936213",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-25T06:23:07.08503",
        "Emailaddresses": "test.user1@heirsholdings.com",
        "Subject": "New Feedback from Lambert Clerk on Requirements Gathering and Documentation"
    }, {
        "Id": "884a617f-1953-4fdb-9d2c-3903091e0529",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-25T06:07:49.583054",
        "Emailaddresses": "test.user1@heirsholdings.com",
        "Subject": "New Assigned Project X project as Committee Member"
    }, {
        "Id": "513a91f3-46c0-4d3a-bea2-c24960b753c3",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-25T06:07:44.187369",
        "Emailaddresses": "ckdaf2000@yahoo.co.uk",
        "Subject": "Test Company's Proposal Request on Testing and Quality Assurance Task"
    }, {
        "Id": "3500c982-1339-4950-aea9-783dc40bdc3d",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-25T06:07:38.503709",
        "Emailaddresses": "ckdaf2000@yahoo.co.uk",
        "Subject": "Test Company's Proposal Request on Design and Development Task"
    }, {
        "Id": "4e141231-04a0-4d61-afa2-a24daa2af24f",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-25T06:07:31.385474",
        "Emailaddresses": "ckdaf2000@yahoo.co.uk",
        "Subject": "Test Company's Proposal Request on Requirements Gathering and Documentation Task"
    }, {
        "Id": "b6172d54-6343-4922-bfc0-2c39467ef713",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-24T08:46:23.960399",
        "Emailaddresses": "jallasugo@gmail.com",
        "Subject": "Requesting Invoice "
    }, {
        "Id": "b0b58d9c-b14a-469e-9379-5c62f37d99bb",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-24T08:44:37.934318",
        "Emailaddresses": "test.user1@heirsholdings.com",
        "Subject": "Approve/Decline Vendor's Contract "
    }, {
        "Id": "2aeabade-57ef-447b-bacf-7d5b3831e969",
        "IsSent": true,
        "IsPending": false,
        "Status": "Sent",
        "Date": "2021-11-24T08:28:53.520804",
        "Emailaddresses": "test.user1@heirsholdings.com",
        "Subject": "Invoice Submitted "
    }]
}