import axios from 'axios';

import { store } from '../../redux/store';

/* const NETWORK_ERROR = {
    status: 'fail',
    message: 'It seems you have lost your internet connection.',
    http_status: 500
};
const UNKNOWN_ERROR = {
    status: 'fail',
    message: 'An unexpected error has occured. Please try again later.',
    http_status: 500
}; */
const AUTH_ERROR = {
    status: 'fail',
    message: 'Your session has expired. Please login to continue.',
    http_status: 401
};

const axiosConfig = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}
let axiosInstance = axios.create(axiosConfig);

const logNetworkResult = (result) => {
    let { config, status, data } = result;
    logNetworkData(config, status, data);
}

const logNetworkError = (result) => {
    if (result.response) {
        let { response } = result;
        let { config, status, data } = response;
        logNetworkData(config, status, data);
    } else {
        console.log(JSON.stringify(result, null, 4));
    }
}

const logNetworkData = (config, status, data) => {
    console.log('\n====================>\n',
        JSON.stringify(config, null, 4),
        '\n====================>');
    console.log('\n<========== HTTP ' + status + ' ==========\n',
        JSON.stringify(data, null, 4),
        '\n<==============================');
}

const apiCall = async (url, method, body, additional_headers) => {
    const { access_token, company_id, preferred_company_id } = store.getState().user.user_data;
    let headers = {
        'X-AuthToken': access_token,
        ...additional_headers
    };

    if (company_id) {
        // Only required for admin call... So we simply don't send unless the signed in user is an admin
        headers['X-CompanyId'] = preferred_company_id || company_id;
    }

    switch (method) {
        case 'post':
        case 'put':
        case 'patch':
        case 'delete':
            return axiosInstance[method](url, body, { headers });
        case 'get':
        default:
            return axiosInstance[method](url, { headers });
    }
};

export const makeApiCall = async (url, method, body = {}, additional_headers = {}) => {
    return new Promise(function (resolve, reject) {
        apiCall(url, method, body, additional_headers).then(result => {
            logNetworkResult(result);
            resolve(result.data);
        }).catch(error => {
            logNetworkError(error);

            if (error.response) {
                let { status, data } = error.response;
                let result = data;
                if (status === 307 || status === 401) {
                    localStorage.clear();
                    window.location.reload();
                    reject(AUTH_ERROR);
                } else {
                    if (typeof result === 'object') {
                        result.http_status = status;
                        // result.message = result.ErrorMessage || result.title || UNKNOWN_ERROR.message
                        result.message = result.ErrorMessage || result.title || JSON.stringify(result);
                    }

                    reject(result);
                }
            } else {
                console.log('===== UNKNOWN ERROR =====\n', JSON.stringify(error, null, 4));
                /* if ('Network Error' === error.message) {
                    reject(NETWORK_ERROR);
                } else {
                    reject(UNKNOWN_ERROR);
                } */
                reject(error);
            }
        });
    });
}