import React, { Component, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { SuspenseFallback } from '../molecules';
import PrivateRoute from './private_route';

import routes from '../../navigations/routes';

class MainContent extends Component {
    render = () => {
        return (
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component && (
                            <PrivateRoute
                                key={idx}
                                exact={route.exact}
                                name={route.name}
                                path={route.path}
                                access={route.role}
                                render={(props) => <route.component {...props} />}
                            />
                        )
                    })}
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </Suspense>
        );
    }
}

export default MainContent;