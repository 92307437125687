import React, { Component } from "react";
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

class ActionHeader extends Component {
    render = () => {
        const {
            title, currentIndex, maximumIndex, stageNames, onNext, onPrev, processing, cancelAction, cancelPath, disabled
        } = this.props;
        return (
            <>
                <div className="px-8 py-3 border-b border-gray-200 flex flex-row flex-wrap justify-between items-center">
                    <h1 className="mb-3 md:mb-0 text-gray-700 page-title flex flex-row items-center">
                        {currentIndex === 0 && (
                            <>
                                {!!cancelAction ? (
                                    <Button className="action-back" disabled={disabled} size="large" onClick={cancelAction}>
                                        <LeftOutlined />
                                    </Button>
                                ) : (
                                    <Link
                                        to={cancelPath}
                                        onClick={(e) => {
                                            if (disabled) e.preventDefault();
                                        }}
                                        disabled={disabled}>
                                        <Button className="action-back" size="large">
                                            <LeftOutlined />
                                        </Button>
                                    </Link>
                                )}
                            </>
                        )}
                        {currentIndex > 0 && (
                            <Button className="action-back" disabled={disabled} size="large" onClick={onPrev}>
                                <LeftOutlined />
                            </Button>
                        )}
                        <span className="ml-5 page-title">{title}</span>
                    </h1>
                </div>
                <div className="px-8">
                    <div className="form-controls flex flex-row justify-between py-4 border-b border-gray-200">
                        <div className="show-steps flex flex-row items-center">
                            <span>{`Step ${currentIndex + 1} of ${maximumIndex + 1}:`}</span>
                            <b className="ml-1">{stageNames[currentIndex]}</b>
                        </div>
                        <div>
                            <Button
                                type="danger"
                                className="flex flex-row items-center justify-between ml-4 rounded-lg"
                                loading={processing}
                                disabled={disabled}
                                onClick={onNext}>
                                <span>Continue</span>
                                <RightOutlined />
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ActionHeader;
