import React, { Component } from "react";
import { connect } from 'react-redux';
import { /* Badge, */ Select } from "antd";
import Search from "antd/lib/input/Search";
// import { BellOutlined } from '@ant-design/icons';

import { updateUserData } from '../../redux/actions/user_actions';
import { loadGroupCompanies } from '../../redux/actions/config_actions';

class ModuleHeader extends Component {
    componentDidMount = () => {
        if (this.props.group_companies.length === 0) {
            this.props.loadGroupCompanies();
        }
    }

    render = () => {
        const { onSearch, searchPlaceholder, onSearchTermChange, showCompanyChange, loading_companies, group_companies } = this.props;
        let company_id = this.props.user_data.preferred_company_id || this.props.user_data.company_id;
        if (company_id) {
            company_id = Number(company_id);
        }

        return (
            <div className="px-8 py-3 border-b border-gray-200 flex flex-row flex-wrap justify-between items-center">
                <h1 className="mb-3 md:mb-0 text-gray-700 page-title">{this.props.title}</h1>
                <div className="flex flex-row align-items-center">
                    {/* <span className="py-1 px-2 rounded-md border-2 border-gray-200 flex flex-row center mr-5">
                        <Badge className="notification-badge" dot>
                            <BellOutlined className="text-2xl" style={{ color: '#5e5e5e' }} />
                        </Badge>
                    </span> */}
                    {!!onSearch && (
                        <Search
                            onSearch={onSearch}
                            style={{ width: 300, height: 44 }}
                            placeholder={searchPlaceholder || 'Enter text to search'}
                            className="border-gray-200 nav-search"
                            onChange={(e) => {
                                if (!!onSearchTermChange) {
                                    onSearchTermChange(e.target.value);
                                }
                            }}
                        />
                    )}
                    {!!showCompanyChange && group_companies.length > 0 && (
                        <Select
                            showSearch
                            optionFilterProp="children"
                            size="large"
                            style={{ width: 300 }}
                            loading={loading_companies}
                            placeholder="Select group company"
                            value={company_id}
                            onSelect={(preferred_company_id) => {
                                if (preferred_company_id !== company_id) {
                                    this.props.updateUserData({ preferred_company_id });
                                    // Refresh window
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 800)
                                }
                            }}>
                            {group_companies.map(
                                (company) => (
                                    <Select.Option key={company.Id} value={company.Id}>{company.Name}</Select.Option>
                                )
                            )}
                        </Select>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading_companies: state.config.loading_companies,
        group_companies: state.config.group_companies,
        user_data: state.user.user_data
    };
};

const mapDispatchToProps = {
    loadGroupCompanies,
    updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleHeader);
