import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { InventoryCategoryMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/InventoryCategory`;

export const getInventoryCategories = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryCategoryMock.GET_INVENTORY_CATEGORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventoryCategories?${Util.objectToQueryString(params)}`, 'get');
}

export const getAllInventoryCategories = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryCategoryMock.GET_ALL_INVENTORY_CATEGORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetAllInventoryCategories`, 'get');
}

export const createInventoryCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateInventoryCategory`, 'post', payload);
}

export const updateInventoryCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateInventoryCategory`, 'post', payload);
}

export const deleteInventoryCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteInventoryCategory`, 'post', payload);
}