import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SuspenseFallback } from '../components/molecules';

import { PrivateRoute } from '../components/organisms/index';
import { AccessToken, ExpiredToken } from '../views/pages/admin/auth';
import VendorLogin from '../views/pages/vendors/auth/login';
import VendorReset from '../views/pages/vendors/auth/reset';
import VendorSignup from '../views/pages/vendors/auth/signup';
import MainLayout from '../views/containers/main_layout';

class AppNavigator extends Component {
    render = () => {
        return (
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                    <Route exact path="/auth" name="Admin Login" render={props => <AccessToken {...props} />} />
                    <Route exact path="/admin/expired-session" pathname="Expired Token" render={props => <ExpiredToken {...props} />} />
                    <Route exact path="/vendors/login" name="Vendor Login" render={props => <VendorLogin {...props} />} />
                    <Route exact path="/vendors/reset" name="Vendor Reset" render={props => <VendorReset {...props} />} />
                    <Route exact path="/vendors/signup" name="Vendor Signup" render={props => <VendorSignup {...props} />} />

                    <PrivateRoute path='/' render={(props) => <MainLayout {...props} />} />
                </Switch>
            </Suspense>
        );
    }
};

export default AppNavigator;
