import { SET_USER_DATA, UPDATE_USER_DATA, CLEAR_USER_DATA } from '../types/user_types';

const initialState = {
    user_data: {}
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA: {
            return {
                ...state,
                user_data: action.user_data
            }
        }
        case UPDATE_USER_DATA: {
            let user_data = { ...state.user_data, ...action.user_data }
            return {
                ...state,
                user_data
            }
        }
        case CLEAR_USER_DATA: {
            return {
                ...state,
                user_data: {}
            }
        }
        default: {
            return state;
        }
    }
};

export default userReducer;