import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { Util } from '../../../../utils';
import { setUserData, clearUserData } from '../../../../redux/actions/user_actions';
import { SuspenseFallback } from '../../../../components/molecules';

class AccessToken extends Component {
    componentDidMount() {
        this.props.clearUserData(); // Double tap...

        const params = queryString.parse(this.props.location.search);
        this.props.history.replace('/auth');

        if (!params || (!params.access_token && !params.acess_token)) {
            this.treatInvalidToken();
        } else {
            const access_token = params.access_token || params.acess_token;

            let user_data = Util.userFromAdminToken(access_token);
            if (process.env.REACT_APP_MOCK !== 'true' && user_data.expires_at.isSameOrBefore()) {
                this.treatInvalidToken();
            } else {
                this.props.setUserData(user_data);
                this.props.history.push('/');
            }
        }
    }

    treatInvalidToken = () => {
        this.props.clearUserData();
        this.props.history.push('/admin/expired-session');
    }

    render = () => {
        return (
            <SuspenseFallback />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = {
    setUserData,
    clearUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessToken);