import React, { Component } from "react";
import { Row } from "antd";
import { Loader } from "../atoms";

class SuspenseFallback extends Component {
    render = () => {
        return (
            <Row type="flex" justify="center" align="middle"
                style={{
                    padding: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: '100vh'
                }}>
                <Loader />
            </Row>
        )
    }
}

export default SuspenseFallback;
