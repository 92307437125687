import React from 'react';

const Dashboard = React.lazy(() => import('../views/pages/dashboard'));

/** Admin */
const Unauthorized = React.lazy(() => import('../views/pages/admin/unauthorized'));
const Vendors = React.lazy(() => import('../views/pages/admin/vendors'));
const CreateVendor = React.lazy(() => import('../views/pages/admin/vendors/create_vendor'));

const AdminProcurements = React.lazy(() => import('../views/pages/admin/procurements'));
const CreateProcurement = React.lazy(() => import('../views/pages/admin/procurements/components/procurement_form'));

const PendingInventoryRequests = React.lazy(() => import('../views/pages/admin/inventory/pending_requests'));
const MyInventoryRequests = React.lazy(() => import('../views/pages/admin/inventory/my_requests'));
const AllInventoryRequests = React.lazy(() => import('../views/pages/admin/inventory/all_requests'));
const InventoryProductHistory = React.lazy(() => import('../views/pages/admin/inventory/product_history'));

const AdminContracts = React.lazy(() => import('../views/pages/admin/contracts'));
const CreateContract = React.lazy(() => import('../views/pages/admin/contracts/create_contract'));

const AdminSettings = React.lazy(() => import('../views/pages/admin/settings'));
const VendorTypeQuestions = React.lazy(() => import('../views/pages/admin/settings/components/vendor_questions/vendor_type_questions'));
const VendorTypeDocuments = React.lazy(() => import('../views/pages/admin/settings/components/vendor_documents/vendor_type_documents'));
const CreateContractTemplate = React.lazy(() => import('../views/pages/admin/settings/components/contract_templates/create_contract_template'));
const ManageContractTemplate = React.lazy(() => import('../views/pages/admin/settings/components/contract_templates/manage_contract_template'));
const CreateProduct = React.lazy(() => import('../views/pages/admin/settings/components/inventory_products/create_product'));

const Audit = React.lazy(() => import('../views/pages/admin/audit'));

const Emails = React.lazy(() => import('../views/pages/admin/emails'));

/** Vendors */
const VendorRegistration = React.lazy(() => import('../views/pages/vendors/registration'));
const VendorTerms = React.lazy(() => import('../views/pages/vendors/agreements/terms'));
const VendorPrivacy = React.lazy(() => import('../views/pages/vendors/agreements/privacy'));

const VendorProcurements = React.lazy(() => import('../views/pages/vendors/procurements'));

const VendorContracts = React.lazy(() => import('../views/pages/vendors/contracts'));

const VendorSettings = React.lazy(() => import('../views/pages/vendors/settings'));

const routes = [
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, role: 'all' },
    { path: '/admin/unauthorized', name: 'Unauthorized', component: Unauthorized, exact: true, role: 'all' },

    { path: '/admin/vendors', name: 'Vendors', component: Vendors, exact: true, role: 'all' },
    { path: '/admin/vendors/create', name: 'Vendors', component: CreateVendor, exact: true, role: 'all' },

    { path: '/admin/procurements', name: 'Procurements', component: AdminProcurements, exact: true, role: 'all' },
    { path: '/admin/procurements/create', name: 'Procurements', component: CreateProcurement, exact: true, role: 'all' },

    { path: '/admin/inventory/pending-requests', name: 'Inventory', component: PendingInventoryRequests, exact: true, role: 'all' },
    { path: '/admin/inventory/my-requests', name: 'Inventory', component: MyInventoryRequests, exact: true, role: 'all' },
    { path: '/admin/inventory/all-requests', name: 'Inventory', component: AllInventoryRequests, exact: true, role: 'admin' },
    { path: '/admin/inventory/product-history', name: 'Inventory', component: InventoryProductHistory, exact: true, role: 'admin' },

    { path: '/admin/contracts', name: 'Contracts', component: AdminContracts, exact: true, role: 'all' },
    { path: '/admin/contracts/create', name: 'Contracts', component: CreateContract, exact: true, role: 'all' },

    { path: '/admin/settings', name: 'Settings', component: AdminSettings, exact: true, role: 'admin' },
    { path: '/admin/settings/vendor-questions', name: 'Settings', component: VendorTypeQuestions, exact: true, role: 'admin' },
    { path: '/admin/settings/vendor-documents', name: 'Settings', component: VendorTypeDocuments, exact: true, role: 'admin' },
    { path: '/admin/settings/contract-templates/create', name: 'Settings', component: CreateContractTemplate, exact: true, role: 'admin' },
    { path: '/admin/settings/contract-templates', name: 'Settings', component: ManageContractTemplate, exact: true, role: 'admin' },
    { path: '/admin/settings/inventory-products/create', name: 'Settings', component: CreateProduct, exact: true, role: 'admin' },
    ///
    { path: '/vendors/registration', name: 'Dashboard', component: VendorRegistration, exact: true, role: 'vendors' },
    { path: '/vendors/terms', name: 'Dashboard', component: VendorTerms, exact: true, role: 'vendors' },
    { path: '/vendors/privacy', name: 'Dashboard', component: VendorPrivacy, exact: true, role: 'vendors' },

    { path: '/vendors/procurements', name: 'Procurements', component: VendorProcurements, exact: true, role: 'vendors' },

    { path: '/vendors/contracts', name: 'Contracts', component: VendorContracts, exact: true, role: 'vendors' },

    { path: '/vendors/settings', name: 'Settings', component: VendorSettings, exact: true, role: 'vendors' },

    { path: '/admin/audit', name: 'Audit', component: Audit, exact: true, role: 'admin' },

    { path: '/admin/emails', name: 'Email Trace', component: Emails, exact: true, role: 'admin' }
];

export default routes;