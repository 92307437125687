import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { VendorMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Vendors`;

export const getCompanies = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_COMPANIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCompanies`, 'get');
}

export const getUsers = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_USERS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetUsers`, 'get');
}

export const getCompanyUsers = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_USERS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetUsers?${Util.objectToQueryString(params)}`, 'get');
}

export const getCompanyRoles = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_COMPANY_ROLES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCompanyRoles`, 'get');
}

export const getVendorTypes = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDOR_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorTypes`, 'get');
}

export const getBanks = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_BANKS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetBanks`, 'get');
}

export const createVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateVendor`, 'post', payload);
}

export const getVendors = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDORS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendors?${Util.objectToQueryString(params)}`, 'get');
}

export const getVendorById = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDOR_BY_ID);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorById?${Util.objectToQueryString(params)}`, 'get');
}

export const updateVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateVendor`, 'post', payload);
}

export const getBlobDocument = async (document_url) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDOR_DOCUMENT);
            }, 1000)
        );
    }

    const containerClient = Util.initAzureContainer();
    const blobClient = containerClient.getBlobClient(document_url);
    const downloadBlockBlobResponse = await blobClient.download();

    return Util.blobToString(await downloadBlockBlobResponse.blobBody);
}

export const getVendorDocumentQueriesByAdmin = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDOR_DOCUMENT_COMMENTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorDocumentQueriesByAdmin?${Util.objectToQueryString(params)}`, 'get');
}

export const getVendorDocumentQueriesByVendor = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDOR_DOCUMENT_COMMENTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorDocumentQueriesByVendor?${Util.objectToQueryString(params)}`, 'get');
}

export const approveVendorDocument = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ApproveDocument`, 'post', payload);
}

export const declineVendorDocument = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RejectDocument`, 'post', payload);
}

export const queryVendorDocument = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/QueriedDocument`, 'post', payload);
}

export const findVendors = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.FIND_VENDORS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/FindVendors?${Util.objectToQueryString(params)}`, 'get');
}

export const isEmailInUseForVendorAccount = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.CHECK_VENDOR_EMAIL);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/IsEmailInUseForVendorAccount?${Util.objectToQueryString(params)}`, 'get');
}

export const authenticateVendor = async (credentials) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.VENDOR_CREDENTIALS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/AuthenticateVendor`, 'post', credentials);
}

export const vendorAcceptTermsAndConditions = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/VendorAcceptTermsAndConditions`, 'post');
}

export const resetVendorPassword = async (credentials) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ResetVendorPassword`, 'post', credentials);
}

export const getMyVendorInformation = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.MY_VENDOR_INFO);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetMyVendorInformation`, 'get');
}

export const getCountries = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_COUNTRIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCountries`, 'get');
}

export const getResidenceTypes = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_RESIDENCE_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetResidenceTypes`, 'get');
}

export const getVendorTypeQuestions = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_QUESTION_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorTypeQuestions?${Util.objectToQueryString(params)}`, 'get');
}

export const getCompanyTypes = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_COMPANY_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCompanyTypes`, 'get');
}

export const getQuestionCategories = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_QUESTION_CATEGORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetQuestionCategories`, 'get');
}

export const getVendorTypeQuestionsByVendor = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_QUESTION_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorTypeQuestionsByVendor?${Util.objectToQueryString(params)}`, 'get');
}

export const completeVendorRegistration = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CompleteVendorRegistration`, 'post', payload);
}

export const saveOrUpdateVendorTypeQuestion = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/SaveOrUpdateVendorTypeQuestion`, 'post', payload);
}

export const changeVendorPassword = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ChangeVendorPassword`, 'post', payload);
}

export const cancelVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CancelVendor`, 'post', payload);
}

export const deleteVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteVendor`, 'post', payload);
}

export const approveVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ApproveVendor`, 'post', payload);
}

export const rejectVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RejectVendor`, 'post', payload);
}

export const processVendorStatusViaApprovalRoute = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ProcessVendorStatusViaApprovalRoute`, 'post', payload);
}

export const blacklistVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/BlacklistVendor`, 'post', payload);
}

export const getTotalPendingVendors = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_TOTAL_PENDING_VENDORS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetTotalPendingVendors`, 'get');
}

export const getRecentVendorDocumentStatuses = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_RECENT_VENDOR_DOC_STATUSES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetRecentVendorDocumentStatuses?${Util.objectToQueryString(params)}`, 'get');
}

export const getTotalVendorsPerCategory = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_TOTAL_VENDORS_PER_CATEGORY);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetTotalVendorsPerCategory`, 'get');
}

export const updateVendorDocumentByVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateVendorDocumentByVendor`, 'post', payload);
}

export const downloadVendors = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.DOWNLOAD_VENDORS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DownloadVendors`, 'get');
}

export const updateMyVendorContact = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve({ Id: "string" });
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateMyVendorContact`, 'post', payload);
}

export const updateMyVendorKYC = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve({ Id: "string" });
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateMyVendorKYC`, 'post', payload);
}

export const updateMyVendorAccount = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve({ Id: "string" });
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateMyVendorAccount`, 'post', payload);
}

export const updateMyVendorDocument = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve({ Id: "string" });
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateMyVendorDocument`, 'post', payload);
}

export const getVendorApprovalSettings = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_APPROVAL_SETTINGS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorApprovalSettings`, 'get');
}

export const updateVendorApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateVendorApprovalSetting`, 'post', payload);
}

export const removeAdminFromVendorApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RemoveAdminFromVendorApprovalSetting`, 'post', payload);
}

export const getApprovedVendors = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorMock.GET_VENDORS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetApprovedVendors?${Util.objectToQueryString(params)}`, 'get');
}

export const canLoggedOnAdminApproveOrRejectVendor = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(true);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CanLoggedOnAdminApproveOrRejectVendor`, 'get');

}

export const canLoggedOnAdminApproveOrRejectVendorDocument = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(true);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CanLoggedOnAdminApproveOrRejectVendorDocument`, 'get');

}