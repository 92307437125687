import React, { Component } from "react";
import { Modal, message, Avatar } from "antd";

import { CustomTable } from '.';

import { ProcurementAdapter } from '../../adapters';

class CommitteeMembers extends Component {
    state = {
        loading: false,
        members: [],
    }

    componentDidMount = () => {
        this.setState({
            loading: true
        }, () => {
            ProcurementAdapter.getAllCommitteeMembers({ committeeId: this.props.committee.Id }).then((members) => {
                this.setState({
                    loading: false,
                    members
                });
            }).catch((error) => {
                message.error(error.message);
                this.props.onClose();
            });
        });
    }

    render = () => {
        const { committee, onClose } = this.props;
        const { loading, members } = this.state;
        return (
            <Modal
                bodyStyle={{ padding: 0 }}
                width={420}
                onCancel={onClose}
                destroyOnClose={true}
                visible={true}
                footer={null}
                title={`${committee?.Name} Members`}>
                <CustomTable
                    rowKey="MemberId"
                    tableClass="vendor-selector unclickable"
                    loading={loading}
                    columns={[
                        {
                            title: "",
                            key: "avatar",
                            textWrap: "nowrap",
                            width: '10%',
                            render: (record) => {
                                return (
                                    <Avatar size={36}
                                        style={
                                            record.MemberId % 2 === 0 ? {
                                                backgroundColor: '#FFF8E3',
                                                color: '#D8A200'
                                            } : {
                                                backgroundColor: '#F6EDFF',
                                                color: '#9B51E0'
                                            }}>
                                        {record.Initials?.replace(/\s+/g, '') || '--'}
                                    </Avatar>
                                );
                            }
                        },
                        {
                            title: "Name",
                            key: "name",
                            textWrap: "nowrap",
                            className: "ellipsis",
                            dataIndex: "Name"
                        }
                    ]}
                    data={members || []}
                />
            </Modal>
        );
    }
}


export default CommitteeMembers;