import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal, Button } from "antd";
import { RightOutlined } from '@ant-design/icons';

class SuccessModal extends Component {
    render = () => {
        const { visible, closable, title, description, redirect, closeLabel } = this.props;
        return (
            <Modal
                bodyStyle={{ padding: 0 }}
                closable={closable}
                width={420}
                visible={visible}
                footer={null}>
                <div className="success-modal py-10 px-6 flex flex-col justify-center text-center">
                    <h2 style={{ textTransform: 'capitalize' }}>{title}</h2>
                    <p className="pt-5 pb-8 description">{description}</p>
                    {redirect && (
                        <div className="flex flex-row justify-center">
                            <Link to={redirect}>
                                <Button
                                    type="danger"
                                    className="flex flex-row justify-between rounded-md items-center"
                                    style={{ width: 150 }}>
                                    {closeLabel} <RightOutlined />
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}
export default SuccessModal;