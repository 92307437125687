import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import { Row, Col, Menu as AntMenu, Avatar } from "antd";
import Icon, { PoweroffOutlined, FileTextOutlined, AuditOutlined, MailOutlined } from '@ant-design/icons';

import { DashboardIcon, VendorIcon, ProcurementIcon, ContractIcon, InventoryIcon, SettingsIcon } from '../icons';
import logo from "../../views/assets/images/logo.png";

import { clearUserData, updateUserData } from '../../redux/actions/user_actions';

class Menu extends Component {
    resetCompanyId = () => {
        // Applicable when leaving admin settings
        this.props.updateUserData({ preferred_company_id: null });
    }

    render = () => {
        const { user_data } = this.props.user;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between'
            }}>
                <div>
                    <div className="logo">
                        <Row
                            type="flex"
                            className="justify-center mt-5 mb-12"
                            align="middle"
                            style={{
                                padding: 20,
                            }}>
                            <img
                                style={{ height: 35 }}
                                alt={user_data.company_name || 'Converge'}
                                src={user_data.company_logo || logo}
                            />
                        </Row>
                    </div>
                    {this.props.location.pathname !== '/vendors/terms' && this.props.location.pathname !== '/vendors/privacy' && (
                        <AntMenu className="text-xs border-0 bg-transparent pb-10"
                            mode="inline"
                            onClick={this.resetCompanyId}>
                            <AntMenu.Item key="dashboard">
                                <NavLink activeClassName="active" to="/dashboard">
                                    <Icon
                                        className="flex align-items-center"
                                        component={() => <DashboardIcon />}
                                    />
                                    <span>Dashboard</span>
                                </NavLink>
                            </AntMenu.Item>
                            {!!user_data.is_registration_pending && (
                                <AntMenu.Item key="registration">
                                    <NavLink activeClassName="active" to={`/${user_data.role}/registration`}>
                                        <FileTextOutlined className="flex align-items-center" style={{ fontSize: 24 }} />
                                        <span>Registration</span>
                                    </NavLink>
                                </AntMenu.Item>
                            )}
                            {(
                                user_data.role === 'admin' &&
                                (
                                    user_data.privileges?.includes('ProcurementVendor') ||
                                    user_data.canApproveOrRejectVendor ||
                                    user_data.canApproveOrRejectVendorDocument)
                            ) && (
                                    <AntMenu.Item key="vendors">
                                        <NavLink activeClassName="active" to={`/${user_data.role}/vendors`}>
                                            <Icon
                                                className="flex align-items-center"
                                                component={() => <VendorIcon />}
                                            />
                                            <span>Vendors</span>
                                        </NavLink>
                                    </AntMenu.Item>
                                )}
                            <AntMenu.Item key="procurements">
                                <NavLink activeClassName="active" to={`/${user_data.role}/procurements`}>
                                    <Icon
                                        className="flex align-items-center"
                                        component={() => <ProcurementIcon />}
                                    />
                                    <span>Procurements</span>
                                </NavLink>
                            </AntMenu.Item>
                            <AntMenu.Item key="contracts">
                                <NavLink activeClassName="active" to={`/${user_data.role}/contracts`}>
                                    <Icon
                                        className="flex align-items-center"
                                        component={() => <ContractIcon />}
                                    />
                                    <span>Contracts</span>
                                </NavLink>
                            </AntMenu.Item>
                            <>
                                <AntMenu.SubMenu
                                    key="inventory"
                                    icon={
                                        <Icon
                                            className="flex align-items-center"
                                            component={() => <InventoryIcon />}
                                        />
                                    }
                                    title="Inventory">
                                    <AntMenu.Item key="my">
                                        <NavLink activeClassName="active" to={`/${user_data.role}/inventory/my-requests`}>
                                            <span>My Requests</span>
                                        </NavLink>
                                    </AntMenu.Item>
                                    <AntMenu.Item key="pending">
                                        <NavLink activeClassName="active" to={`/${user_data.role}/inventory/pending-requests`}>
                                            <span>Pending Requests</span>
                                        </NavLink>
                                    </AntMenu.Item>
                                    {user_data.role === 'admin' && (
                                        <>
                                            <AntMenu.Item key="all">
                                                <NavLink activeClassName="active" to={`/${user_data.role}/inventory/all-requests`}>
                                                    <span>All Requests</span>
                                                </NavLink>
                                            </AntMenu.Item>
                                            <AntMenu.Item key="history">
                                                <NavLink activeClassName="active" to={`/${user_data.role}/inventory/product-history`}>
                                                    <span>Product History</span>
                                                </NavLink>
                                            </AntMenu.Item>
                                        </>
                                    )}
                                </AntMenu.SubMenu>
                            </>
                            {(user_data.role === 'vendors' ||
                                (user_data.role === 'admin' && user_data.privileges?.includes('ProcurementSetting')))
                                && (
                                    <AntMenu.Item key="settings">
                                        <NavLink activeClassName="active" to={`/${user_data.role}/settings`}>
                                            <Icon
                                                className="flex align-items-center"
                                                component={() => <SettingsIcon />}
                                            />
                                            <span>Settings</span>
                                        </NavLink>
                                    </AntMenu.Item>
                                )}
                            {user_data.role === 'admin' && user_data.privileges?.includes('ProcurementEmail') && (
                                <AntMenu.Item key="email">
                                    <NavLink activeClassName="active" to={`/${user_data.role}/emails`}>
                                        <MailOutlined className="flex align-items-center" style={{ fontSize: 24 }} />
                                        <span>Email Trace</span>
                                    </NavLink>
                                </AntMenu.Item>
                            )}
                            {user_data.privileges?.includes('ProcurementAudit') && (
                                <AntMenu.Item key="audit">
                                    <NavLink activeClassName="active" to={`/${user_data.role}/audit`}>
                                        <AuditOutlined className="flex align-items-center" style={{ fontSize: 24 }} />
                                        <span>Audit Trail</span>
                                    </NavLink>
                                </AntMenu.Item>
                            )}
                            {user_data.role === 'vendors' && (
                                <AntMenu.Item key="logout">
                                    <NavLink to="/logout"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.clearUserData();
                                        }}>
                                        <PoweroffOutlined className="flex align-items-center" style={{ fontSize: 24 }} />
                                        <span>Logout</span>
                                    </NavLink>
                                </AntMenu.Item>
                            )}
                        </AntMenu>
                    )}
                </div>
                <div>
                    {user_data.role === 'vendors' && (
                        <Row className="align-items-center" style={{ padding: 20 }}>
                            <Col xs={4}>
                                <Avatar size={40} />
                            </Col>
                            <Col xs={20}>
                                <div className="ml-2 capitalize ellipsis"
                                    style={{
                                        fontWeight: '500',
                                        fontSize: '16px'
                                    }}>{user_data.name}</div>
                                <div className="ml-2 capitalize ellipsis text-gray-400">{user_data.vendor_type_name}</div>
                            </Col>
                        </Row>
                    )}
                    <Row
                        type="flex"
                        className="justify-center"
                        align="middle"
                        style={{
                            padding: 20,
                        }}>
                        <img style={{ height: 35 }} alt="Converge" src={logo} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = {
    clearUserData,
    updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));