import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { Link } from 'react-router-dom';

import { Layout, Row, Col, Form, Input, Alert, Button, message } from "antd";
import { RightOutlined } from '@ant-design/icons';

import logo from "../../../assets/images/logo.png";
import { VendorAdapter } from '../../../../adapters/index';

import { clearUserData } from '../../../../redux/actions/user_actions';

const { Content } = Layout;

class VendorReset extends Component {
    resetForm = React.createRef();
    state = {
        reset_error: '',
        processing: false
    }

    componentDidMount = () => {
        this.props.clearUserData(); // Double Tap
    }

    resetError = () => {
        this.setState({ reset_error: '' });
    }

    handleSubmit = () => {
        this.resetForm.current.validateFields().then((values) => {
            this.setState({
                processing: true,
                reset_error: ''
            }, () => {
                VendorAdapter.resetVendorPassword(values).then(() => {
                    this.setState({ processing: false });
                    message.success("The reset email has been sent successfully.");
                    this.props.history.push('/vendors/login');
                }).catch((reset_error) => {
                    this.setState({
                        processing: false,
                        reset_error: reset_error.message
                    });
                });
            });
        }).catch((errors) => {
            console.log("Form validation errors: ", JSON.stringify(errors, null, 4))
        });
    }

    render = () => {
        const { processing, reset_error } = this.state;
        return (
            <Layout>
                <Helmet title={`Procurement ${this.props.name ? `| ${this.props.name}` : ''}`} />
                <Content>
                    <Row>
                        <Col className="start-banner d-sm-down-none" xs={0} sm={0} md={12} span={24}>
                            <img className="welcome-logo" alt="Heirs Procurement" src={logo} />
                        </Col>
                        <Col className="full-screen-height md:p-24" xs={24} sm={24} md={12} span={24}>
                            <Form
                                ref={this.resetForm}
                                className="login-form">
                                <Row>
                                    <Col xs={24}>
                                        <div className="welcome-msg">Reset your account password</div>
                                    </Col>
                                </Row>
                                {!!reset_error && (
                                    <Row className="mt-2">
                                        <Col xs={24}>
                                            <div className="text-center">
                                                <Alert type="error" message={reset_error} closable />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Form.Item name="EmailAddress" style={{ marginBottom: 5, marginTop: 10 }}
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Please, enter a valid email address",
                                        },
                                    ]}>
                                    <div className="custom-input floating-label">
                                        <Input
                                            onChange={this.resetError}
                                            disabled={processing}
                                            placeholder="Email address"
                                        />
                                        <label>Email address</label>
                                    </div>
                                </Form.Item>
                                <div className="flex align-items-center mt-10" style={{ flexWrap: "wrap" }}>
                                    <Button
                                        type="danger"
                                        block
                                        htmlType="submit"
                                        loading={processing}
                                        className="flex flex-row items-center justify-between rounded-lg"
                                        style={{ height: '45px' }}
                                        onClick={this.handleSubmit}>
                                        <span>Reset Password</span>
                                        <RightOutlined />
                                    </Button>
                                </div>
                                <Col className="mt-3" span={24}>
                                    <Link
                                        to="/vendors/login"
                                        disabled={processing}
                                        onClick={(e) => {
                                            if (processing) e.preventDefault();
                                        }}>
                                        <span className="font-semibold text-red-500 cursor-pointer">Back to login</span>
                                    </Link>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapDispatchToProps = {
    clearUserData
};

export default connect(null, mapDispatchToProps)(VendorReset);