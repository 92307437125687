import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { VendorCategoryMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/VendorCategory`;

export const createVendorCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateVendorCategory`, 'post', payload);
}

export const getAllVendorCategories = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorCategoryMock.GET_ALL_VENDOR_CATEGORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetAllVendorCategories`, 'get');
}

export const getVendorCategories = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(VendorCategoryMock.GET_VENDOR_CATEGORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorCategories?${Util.objectToQueryString(params)}`, 'get');
}

export const updateVendorCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateVendorCategory`, 'post', payload);
}

export const deleteVendorCategory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteVendorCategory`, 'post', payload);
}