import React, { Component } from "react";

class SuccessfulUploadIcon extends Component {
    render = () => {
        return (
            <svg
                height={28}
                width={24}
                viewBox="0 0 24 28"
                style={{ display: "inline" }}>
                <path
                    d="M17.3454 19.5493C17.1505 19.2548 16.7329 19.1959 16.4823 19.4609L15.8141 20.1381C15.5635 20.4031 15.1737 20.3442 14.951 20.0792L12.835 17.0466C12.5844 16.6933 12.1111 16.7228 11.8883 17.0761L8.24102 23.2296C7.99044 23.6418 8.26886 24.1718 8.71433 24.1718H19.3501C19.8234 24.1718 20.1018 23.6123 19.8234 23.2001L17.3454 19.5493Z"
                    fill="#27AE60"
                />
                <path
                    d="M17.9024 17.6063C18.7174 17.6063 19.378 16.9076 19.378 16.0458C19.378 15.184 18.7174 14.4854 17.9024 14.4854C17.0874 14.4854 16.4268 15.184 16.4268 16.0458C16.4268 16.9076 17.0874 17.6063 17.9024 17.6063Z"
                    fill="#27AE60"
                />
                <path
                    d="M23.7494 10.6583L18.1531 4.71083C17.9861 4.53417 17.7633 4.44585 17.5406 4.44585L12.3619 4.4164C11.471 1.85489 9.13225 0 6.37587 0C2.86775 0.0294427 0 3.06204 0 6.77182C0 9.6572 1.72622 12.1304 4.14849 13.102V25.8801C4.14849 27.0578 5.03944 28 6.15313 28H21.9954C23.1091 28 24 27.0578 24 25.8801V11.2766C24 11.041 23.9165 10.8055 23.7494 10.6583ZM18.4037 7.56677L21.0766 10.3933H18.4037V7.56677ZM1.72622 6.77182C1.72622 4.06309 3.81439 1.82545 6.40371 1.82545C8.99304 1.82545 11.0812 4.03365 11.0812 6.77182C11.0812 9.50999 8.99304 11.7182 6.40371 11.7182C3.81439 11.7182 1.72622 9.50999 1.72622 6.77182ZM21.9954 26.1746H6.15313C5.98608 26.1746 5.87471 26.0273 5.87471 25.8801V13.5142C6.04176 13.5436 6.20882 13.5436 6.40371 13.5436C9.91183 13.5436 12.7796 10.511 12.7796 6.80126C12.7796 6.62461 12.7796 6.44795 12.7517 6.27129H16.6775V11.2766C16.6775 11.7771 17.0673 12.1893 17.5406 12.1893H22.2738V25.8507C22.2738 26.0273 22.1624 26.1746 21.9954 26.1746Z"
                    fill="#27AE60"
                />
                <path
                    d="M8.85418 6.24151C9.18829 5.8882 9.16045 5.29935 8.82634 4.94604C8.49223 4.59272 7.93539 4.62217 7.60128 4.97548L5.84722 6.97758L5.0398 6.0943C4.70569 5.74099 4.17669 5.71154 3.81474 6.06486C3.48063 6.41817 3.45279 6.97758 3.7869 7.36034L5.20685 8.97968C5.37391 9.15634 5.59664 9.27411 5.84722 9.27411C6.0978 9.27411 6.32054 9.15634 6.48759 8.97968L8.85418 6.24151Z"
                    fill="#27AE60"
                />
            </svg>
        )
    }
}

export default SuccessfulUploadIcon;
