import React, { Component } from 'react';
import { Col, message, Row, Upload } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { PendingUploadIcon, SuccessfulUploadIcon } from "../icons";

import { Util } from "../../utils";

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
class FileUpload extends Component {
    state = {
        uploading: false,
        error: null
    }

    handleChange = (info) => {
        this.setState({
            error: null
        }, () => {
            let fileList = [...info.fileList];
            if (fileList.length) {
                fileList = [fileList[fileList.length - 1]];
            } else {
                fileList = [];
            }

            if (fileList[0]?.size > 2097152) {
                this.setState({
                    error: 'Exceeds maximum file size allowed'
                });
            } else {
                this.props.setFileList(fileList);
                if (fileList.length > 0 && fileList[0].originFileObj) {
                    this.azureUpload(fileList[0]);
                }
            }
        });
    };

    azureUpload = async (fileObject) => {
        if (USE_MOCK) {
            this.props.setFileList([{ ...fileObject, fileName: fileObject.originFileObj.name || 'mock.pdf' }]);
        } else {
            this.setState({
                uploading: true
            }, async () => {
                let file = fileObject.originFileObj;
                const containerClient = Util.initAzureContainer();

                const fileName = file.name.substr(0, file.name.lastIndexOf(".")) || file.name;
                const blobName = fileName + "-" + new Date().getTime() + this.fileExt(file.name);
                const blockBlobClient = containerClient.getBlockBlobClient(blobName);
                const options = { blobHTTPHeaders: { blobContentType: file.type } };

                try {
                    const uploadBlobResponse = await blockBlobClient.uploadData(file, options);
                    console.log(JSON.stringify(uploadBlobResponse, null, 4));

                    this.props.setFileList([{ ...fileObject, fileName: blobName }]);
                    message.success(`${file.name} uploaded successfully`);
                    this.setState({
                        uploading: false
                    });
                } catch (error) {
                    message.error("unable to upload file");
                    this.props.setFileList([]);
                    this.setState({
                        uploading: false
                    });
                }
            });
        }
    };

    fileExt = (fileName) => {
        var parts = fileName.split(".");
        if (parts.length === 1 || (parts[0] === "" && parts.length === 2)) {
            return "";
        }
        return "." + parts.pop();
    };

    render = () => {
        let title_length = !!this.props.fileTitle ? 9 : 0;
        let expiry_length = !!this.props.hasExpiry ? 3 : 0;
        return (
            <Row justify={this.props.justify}>
                {!!this.props.fileTitle && (
                    <Col xs={24} sm={title_length} className="pt-1">
                        {this.props.fileTitle}
                    </Col>
                )}
                <Col xs={24} sm={24 - title_length - expiry_length} className={!!this.props.hasExpiry ? "mr-2" : ""}>
                    <Upload
                        disabled={this.props.disabled || this.state.uploading}
                        showUploadList={false}
                        name="file"
                        beforeUpload={(file) => { return false; }}
                        accept=".png, .jpg, .jpeg, .pdf"
                        multiple={false}
                        onChange={this.handleChange}>
                        <>
                            <div
                                className="flex flex-row justify-between py-3 px-5 border rounded-md border-gray-200 align-items-center"
                                style={!!this.props.error ? { borderColor: '#ff4d4f' } : {}}>
                                <div className="placeholder">
                                    {this.state.uploading ? (
                                        <span className="text-gray-400">
                                            Uploading
                                            {this.props.fileList && this.props.fileList[0]
                                                ? " " + this.props.fileList[0].name
                                                : ""}
                                            ...
                                        </span>
                                    ) : this.props.fileList && this.props.fileList.length ? (
                                        <> {this.props.fileList[0].name}</>
                                    ) : (
                                        <>Browse for file</>
                                    )}
                                </div>
                                <div className="icon">
                                    {this.state.uploading ? (
                                        <LoadingOutlined style={{ fontSize: 20, color: "#888" }} />
                                    ) : (
                                        <>
                                            {this.props.fileList && this.props.fileList[0] ? (
                                                <>
                                                    {this.props.fileList[0].fileName ? (
                                                        <SuccessfulUploadIcon />
                                                    ) : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                if (this.props.fileList && this.props.fileList[0]) {
                                                                    this.azureUpload(this.props.fileList[0]);
                                                                }
                                                            }}>
                                                            <span className="text-red-400">retry</span>
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <PendingUploadIcon />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {(!!this.props.error || !!this.state.error) && (
                                <div className="mt-1 ant-form-item-explain ant-form-item-explain-error">
                                    {this.props.error || this.state.error}
                                </div>
                            )}
                            <div className="mt-1 text-gray-400" style={{ fontSize: '12px' }}>
                                <span className="block">
                                    Allowed file types: .png, .jpg, .jpeg, .pdf
                                </span>
                                <span className="block">
                                    Maximum file size: 2MB
                                </span>
                            </div>
                        </>
                    </Upload>
                </Col>
            </Row>
        );
    }
}

export default FileUpload;