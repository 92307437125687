import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

class CoverPanel extends Component {
    render = () => {
        return (
            <div className="absolute bottom-0 top-0 left-0 right-0 bg-white bg-opacity-80 transition-opacity">
                <div className="flex flex-col full-height pt-20 items-center opacity-60 transition-opacity font-bold">
                    {this.props.loading && (<LoadingOutlined className="loader" />)}
                    {this.props.message || "Please wait..."}
                </div>
            </div>
        )
    }
}

export default CoverPanel;
