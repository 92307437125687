import React, { Component } from 'react';
import { Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import AppNavigator from "./navigations/app_navigator";

import './styles/style.scss';

class App extends Component {
  render = () => {
    return (
      <BrowserRouter>
        <Switch>
          <AppNavigator />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
