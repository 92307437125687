export const GET_VENDOR_CATEGORIES = {
    "TotalCount": 12,
    "CurrentPage": 0,
    "ExpectedPageSize": 20,
    "Results": [
        {
            "Id": 9,
            "Name": "General Business"
        },
        {
            "Id": 8,
            "Name": "string"
        },
        {
            "Id": 7,
            "Name": "Side Category"
        },
        {
            "Id": 6,
            "Name": "Second Test Category"
        },
        {
            "Id": 5,
            "Name": "New test Category"
        },
        {
            "Id": 4,
            "Name": "Fabric"
        },
        {
            "Id": 3,
            "Name": "Automobiles"
        },
        {
            "Id": 2,
            "Name": "Construction"
        },
        {
            "Id": 1,
            "Name": "Technology"
        }
    ]
}

export const GET_ALL_VENDOR_CATEGORIES = [
    {
        "Id": 1,
        "Name": "Technology"
    }, {
        "Id": 2,
        "Name": "Construction"
    }, {
        "Id": 3,
        "Name": "Automobiles"
    }, {
        "Id": 4,
        "Name": "Fabric"
    }
]
