export const GET_CONTRACT_TEMPLATES = [{
    "Id": 2,
    "Template": "<p>[[Title]]</p>\n<p><span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 12px;font-family: Inter, sans-serif;\">[[Contract Type]]</span></p>\n<p><span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 12px;font-family: Inter, sans-serif;\">[[Scope of Work</span></p>\n<p><span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 12px;font-family: Inter, sans-serif;\">[[Deliverables]]</span></p>\n<p><span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 12px;font-family: Inter, sans-serif;\">[[Vendor Address]</span></p>\n<p>Thanks</p>",
    "Name": "Testings",
    "Description": "Testing "
}, {
    "Id": 1,
    "Template": "<p style=\"text-align:center;\"><strong><ins>A Simple Contract</ins></strong></p>\n<p>This is a contract agreement between [[Vendor Name]] (hereinafter referred to as the \"<strong>Vendor</strong>\" and HH company (hereinafter referred to as the \"<strong>Company</strong>\".</p>\n<p>The vendor agrees to execute a [[Contract Type]] titled <strong>[[Title]]</strong> to their best ability. The contract is in respect of the task <span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 14px;font-family: Inter, sans-serif;\"><em>[[Project Task]]</em></span> under project <span style=\"color: rgba(0,0,0,0.85);background-color: rgb(255,255,255);font-size: 14px;font-family: Inter, sans-serif;\"><em>[[Project]]</em></span><em> </em></p>\n<p><strong><ins>Scope of work:</ins></strong></p>\n<p>[[Scope of Work]]</p>\n<p><strong><ins>Deliverables:</ins></strong></p>\n<p>[[Deliverables]]</p>\n<p></p>\n<p>The vendor also agrees to send any and all clarifications, complaints, and or suggestions to the initiating personnel - <strong>[[Initiated By]]</strong>.</p>\n<p style=\"text-align:right;\">That's not all.</p>\n<p style=\"text-align:right;\">Signed, Management</p>",
    "Name": "This is a very very long contract title",
    "Description": "A brief description of the main contract can span another line if it's also very long"
}]

export const GET_CONTRACTS = {
    "TotalCount": 19,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "ContractId": "07fe658e-e4a6-4df7-9e74-ddd61a2c7079",
        "Title": "testing",
        "VendorId": "91a443b3-19b6-49bb-a475-7300d64a2c39",
        "ConvergeVendorId": 0,
        "VendorName": "Ohmsflex_1",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-11-03T06:58:57.150402",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "71fde085-2dbb-416a-b64d-f744f24d0237",
        "Title": "Multiple doc contract",
        "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
        "ConvergeVendorId": 0,
        "VendorName": "Lambert Clerk",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-11-03T04:05:01.667521",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "007ec762-f242-4337-8aec-487257e88efb",
        "Title": "No doc contract",
        "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
        "ConvergeVendorId": 0,
        "VendorName": "Lambert Clerk",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-11-03T04:03:22.706389",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "facc7ed3-fde0-42d0-9641-55e73ddadbe2",
        "Title": "testing_jallas",
        "VendorId": "91a443b3-19b6-49bb-a475-7300d64a2c39",
        "ConvergeVendorId": 0,
        "VendorName": "Ohmsflex_1",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-11-02T08:23:08.252053",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "162c12e6-998a-43f8-bac0-445a118cdb79",
        "Title": "Lebu Constable Implementation",
        "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
        "ConvergeVendorId": 0,
        "VendorName": "Lambert Clerk",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-11-02T06:07:11.604625",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "8c35f89a-8fd5-44aa-92de-94ea658280db",
        "Title": "sdcsdve",
        "VendorId": "493ea90b-ea0d-443f-8768-0a6c6da2f71f",
        "ConvergeVendorId": 0,
        "VendorName": "OHMSFLEX NIG LTD_3",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-10-28T09:50:48.065383",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "c717eb87-8f9a-4ef6-8348-701d8da0c9ab",
        "Title": "kljwkehwjehkj",
        "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
        "ConvergeVendorId": 0,
        "VendorName": "Lambert Clerk",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-10-27T22:58:55.592864",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "39bd11c5-7abe-4949-8cdc-44bdf861a21c",
        "Title": "TEF Forum Internet",
        "VendorId": "91a443b3-19b6-49bb-a475-7300d64a2c39",
        "ConvergeVendorId": 0,
        "VendorName": "Ohmsflex_1",
        "ContractStatus": 2,
        "ContractStatusName": "Approved",
        "VendorContractStatus": 1,
        "VendorContractStatusName": "Approved",
        "VendorContractStatusReason": "",
        "CreatedDate": "2021-10-13T16:20:18.05305",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 1,
            "ContractStatusName": "Approved",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 1,
            "ContractStatusName": "Approved",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "4bb33993-5d15-4b0f-afff-32f8054eb256",
        "Title": "purchase serverds",
        "VendorId": "493ea90b-ea0d-443f-8768-0a6c6da2f71f",
        "ConvergeVendorId": 0,
        "VendorName": "OHMSFLEX NIG LTD_3",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-10-13T16:13:04.155308",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }, {
        "ContractId": "c99265d0-708a-4174-a94f-480cc4600c65",
        "Title": "Supply of Laptops",
        "VendorId": "493ea90b-ea0d-443f-8768-0a6c6da2f71f",
        "ConvergeVendorId": 0,
        "VendorName": "OHMSFLEX NIG LTD_3",
        "ContractStatus": 0,
        "ContractStatusName": "In House Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-10-13T16:06:19.272031",
        "ApprovalSettings": [{
            "ApprovalSettingId": 8,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 0
        }, {
            "ApprovalSettingId": 9,
            "ApprovalUserId": 3,
            "ApprovalUserName": "Test User1",
            "ContractStatus": 0,
            "ContractStatusName": "Pending",
            "ReasonOfRejection": null,
            "OrderBy": 1
        }],
        "Amount": 0,
        "_junks": null,
        "SupportingDocumentUrls": null
    }]
}

export const GET_CONTRACT_TEMPLATE_TAGS = [
    {
        "Id": 1,
        "Name": "first_tag"
    },
    {
        "Id": 2,
        "Name": "another_tag"
    },
]

export const GET_CONTRACT_TYPES = [{
    "Id": 1,
    "Name": "Purchase Order"
}, {
    "Id": 2,
    "Name": "Contract"
}]

export const GET_APPROVAL_SETTINGS = [
    {
        "Id": 3,
        "RoleId": 0,
        "RoleName": null,
        "OrderBy": 2,
        "ApprovingUserId": 952,
        "ApprovingUserName": "TEST USER2"
    },
    {
        "Id": 2,
        "RoleId": 0,
        "RoleName": null,
        "OrderBy": 1,
        "ApprovingUserId": 3,
        "ApprovingUserName": "Test User1"
    }
]

export const GET_VENDOR_CONTRACTS = {
    "TotalCount": 1,
    "CurrentPage": 0,
    "ExpectedPageSize": 10,
    "Results": [{
        "ContractId": "81ee71ed-33e4-4703-a030-45f4464d6449",
        "Title": "HH Test mobile app ",
        "TaskName": "Develop mobile app",
        "ProjectName": "Development of test mobile app",
        "ContractStatus": 1,
        "ContractStatusName": "Pending",
        "VendorContractStatus": 0,
        "VendorContractStatusName": "Pending",
        "VendorContractStatusReason": null,
        "CreatedDate": "2021-09-27T10:13:02.576629"
    }]
}

export const GET_CONTRACT_BY_ID = {
    "ScopeOfWork": "Customer support app using React Native",
    "Deliverables": "- Working android build\n- Working iOS build\n- Code pushed to internal repository\n- Application approved and published in both android and iOS stores",
    "ContractType": 1,
    "ContractTypeName": "Mini Contract",
    "InitiatedBy": 3,
    "ProjectTaskId": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
    "ProjectTaskName": "Develop mobile app",
    "ProjectTaskDescription": "Cross platform application using react-native",
    "ProjectDescription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "FormattedTemplateContent": "<p style=\"text-align:center;\"><strong><ins>A Simple Contract</ins></strong></p>\n<p>HH Test mobile app  Customer support app using React Native - Working android build<br>- Working iOS build<br>- Code pushed to internal repository<br>- Application approved and published in both android and iOS stores Mini Contract - Working android build<br>- Working iOS build<br>- Code pushed to internal repository<br>- Application approved and published in both android and iOS stores Lambert Clerk Clerk Lambert Loki Estate, Lambert Avenue, Lagos Nigeria Develop mobile app Development of test mobile app</p>\n<p></p>\n<p style=\"text-align:right;\">That's all.</p>\n<p style=\"text-align:right;\">Signed, Management</p>",
    "Milestones": [{
        "Id": "518025ff-d9c5-4e62-a1ae-757f4472cc6c",
        "Title": "Mobilization Fee",
        "PaymentDueDate": "2021-10-01T10:10:22.25",
        "PercentageToBePaid": 35,
        "Amount": 0.0000000000000000000000000000,
        "InvoiceURL": null,
        "MilestoneStatus": 0,
        "MilestoneStatusName": "Pending"
    }, {
        "Id": "97ad243e-1461-423d-8661-dad0b597d884",
        "Title": "70% Progression",
        "PaymentDueDate": "2021-10-15T10:10:44.477",
        "PercentageToBePaid": 60,
        "Amount": 0.0000000000000000000000000000,
        "InvoiceURL": null,
        "MilestoneStatus": 0,
        "MilestoneStatusName": "Pending"
    }, {
        "Id": "cd4b6dcb-4232-4cf7-94ba-3258e5e3d137",
        "Title": "Post go-live balance",
        "PaymentDueDate": "2021-10-31T10:11:15.617",
        "PercentageToBePaid": 5,
        "Amount": 0.0000000000000000000000000000,
        "InvoiceURL": null,
        "MilestoneStatus": 0,
        "MilestoneStatusName": "Pending"
    }],
    "NextContractApproval": null,
    "ContractId": "81ee71ed-33e4-4703-a030-45f4464d6449",
    "Title": "HH Test mobile app ",
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "ConvergeVendorId": 0,
    "VendorName": "Lambert Clerk",
    "ContractStatus": 2,
    "ContractStatusName": "Approved",
    "VendorContractStatus": 1,
    "VendorContractStatusName": "Approved",
    "VendorContractStatusReason": "",
    "CreatedDate": "2021-09-27T10:13:02.576629",
    "ApprovalSettings": [{
        "ApprovalSettingId": 8,
        "ApprovalUserId": 3,
        "ApprovalUserName": "Test User1",
        "ContractStatus": 1,
        "ContractStatusName": "Approved",
        "ReasonOfRejection": null,
        "OrderBy": 0
    }],
    "Amount": 0.0000000000000000000000000000,
    "_junks": "",
    "SupportingDocumentUrls": ["mtn-1632737575939.png"]
}

export const GET_VENDOR_CONTRACT_BY_ID = {
    "ScopeOfWork": "Customer support app using React Native",
    "Deliverables": "- Working android build\n- Working iOS build\n- Code pushed to internal repository\n- Application approved and published in both android and iOS stores",
    "SupportingDocumentUrl": "mtn-1632737575939.png",
    "ContractType": 1,
    "ContractTypeName": "Mini Contract",
    "InitiatedBy": 3,
    "VendorId": "43b9e979-61a1-4bf7-8c10-a2ac65c2ac3e",
    "ProjectTaskId": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
    "ProjectTaskName": "Develop mobile app",
    "ProjectTaskDescription": "Cross platform application using react-native",
    "ProjectDescription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "FormattedTemplateContent": "<p style=\"text-align:center;\"><strong><ins>A Simple Contracts</ins></strong></p>\n<p>HH Test mobile app  Customer support app using React Native - Working android build<br>- Working iOS build<br>- Code pushed to internal repository<br>- Application approved and published in both android and iOS stores Mini Contract - Working android build<br>- Working iOS build<br>- Code pushed to internal repository<br>- Application approved and published in both android and iOS stores Lambert Clerk Clerk Lambert Loki Estate, Lambert Avenue, Lagos Nigeria Develop mobile app Development of test mobile app</p>\n<p></p>\n<p style=\"text-align:right;\">That's not all.</p>\n<p style=\"text-align:right;\">Signed, Management</p>",
    "Milestones": [{
        "Id": "518025ff-d9c5-4e62-a1ae-757f4472cc6c",
        "Title": "Mobilization Fee",
        "PaymentDueDate": "2021-10-01T10:10:22.25",
        "PercentageToBePaid": 35,
        "InvoiceURL": null
    }, {
        "Id": "97ad243e-1461-423d-8661-dad0b597d884",
        "Title": "70% Progression",
        "PaymentDueDate": "2021-10-15T10:10:44.477",
        "PercentageToBePaid": 60,
        "InvoiceURL": null
    }, {
        "Id": "cd4b6dcb-4232-4cf7-94ba-3258e5e3d137",
        "Title": "Post go-live balance",
        "PaymentDueDate": "2021-10-31T10:11:15.617",
        "PercentageToBePaid": 5,
        "InvoiceURL": null
    }],
    "ContractId": "81ee71ed-33e4-4703-a030-45f4464d6449",
    "Title": "HH Test mobile app ",
    "VendorName": "Lambert Clerk",
    "ContractStatus": 1,
    "ContractStatusName": "Pending",
    "VendorContractStatus": 0,
    "VendorContractStatusName": "Pending",
    "VendorContractStatusReason": null,
    "CreatedDate": "2021-09-27T10:13:02.576629"
}

export const GET_EXISTING_TASK_CONTRACTS = [
    {
        "Id": "10069f89-4fe3-4f30-b4ab-258da42f1ebd",
        "Name": "Develop mobile app",
        "Amount": 0
    }
]