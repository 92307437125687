import { SET_VISIBILITY, SET_ACTIVE_MODULE } from '../types/sidebar_types';

export const setVisibility = (visibility) => {
    return dispatch => {
        dispatch({
            type: SET_VISIBILITY,
            visibility
        })
    };
};

export const setActiveModule = (active_module) => {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_MODULE,
            active_module
        })
    };
};