import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { Link } from 'react-router-dom';

import { Layout, Row, Col, Form, Input, Alert, Button } from "antd";
import { RightOutlined } from '@ant-design/icons';

import logo from "../../../assets/images/logo.png";
import { Util } from '../../../../utils';
import { VendorAdapter } from '../../../../adapters/index';

import { setUserData, clearUserData } from '../../../../redux/actions/user_actions';

const { Content } = Layout;

class VendorLogin extends Component {
    loginForm = React.createRef();
    state = {
        login_error: '',
        processing: false
    }

    componentDidMount = () => {
        this.props.clearUserData(); // Double Tap
    }

    resetError = () => {
        this.setState({ login_error: '' });
    }

    handleSubmit = () => {
        this.loginForm.current.validateFields().then((values) => {
            this.setState({ processing: true }, () => {
                VendorAdapter.authenticateVendor(values).then((login_result) => {
                    this.props.setUserData({ access_token: login_result.JWTToken });
                    // We keep the vendor waiting while we fetch his profile
                    VendorAdapter.getMyVendorInformation().then((vendor_data) => {
                        let user_data = Util.userFromVendorLogin(login_result, vendor_data);
                        this.props.setUserData(user_data);
                        this.setState({ processing: false }, () => {
                            this.props.history.push(Util.determineVendorRoute(login_result));
                        });
                    }).catch((vendor_error) => {
                        this.setState({
                            processing: false,
                            login_error: vendor_error.message
                        });
                    });
                }).catch((login_error) => {
                    this.setState({
                        processing: false,
                        login_error: login_error.message
                    });
                });
            });
        }).catch((errors) => {
            console.log("Form validation errors: ", JSON.stringify(errors, null, 4))
        });
    }

    render = () => {
        const { processing, login_error } = this.state;
        return (
            <Layout>
                <Helmet title={`Procurement ${this.props.name ? `| ${this.props.name}` : ''}`} />
                <Content>
                    <Row>
                        <Col className="start-banner d-sm-down-none" xs={0} sm={0} md={12} span={24}>
                            <img className="welcome-logo" alt="Heirs Procurement" src={logo} />
                        </Col>
                        <Col className="full-screen-height md:p-24" xs={24} sm={24} md={12} span={24}>
                            <Form
                                ref={this.loginForm}
                                className="login-form">
                                <Row>
                                    <Col xs={24}>
                                        <div className="welcome-msg">Sign in to your account</div>
                                    </Col>
                                </Row>
                                {!!login_error && (
                                    <Row className="mt-2">
                                        <Col xs={24}>
                                            <div className="text-center">
                                                <Alert type="error" message={login_error} closable />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Form.Item name="EmailAddress" style={{ marginBottom: 5, marginTop: 10 }}
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Please, enter a valid email address",
                                        },
                                    ]}>
                                    <div className="custom-input floating-label">
                                        <Input
                                            onChange={this.resetError}
                                            disabled={processing}
                                            placeholder="Email address"
                                        />
                                        <label>Email address</label>
                                    </div>
                                </Form.Item>
                                <Form.Item name="Password" rules={[
                                    {
                                        required: true,
                                        min: 6,
                                        message:
                                            "Please, enter a valid password (min 6 characters)",
                                    }
                                ]}>
                                    <div className="custom-input floating-label">
                                        <Input.Password disabled={processing} placeholder="Password" onChange={this.resetError} />
                                        <label>Password</label>
                                    </div>
                                </Form.Item>
                                <div className="flex align-items-center mt-10" style={{ flexWrap: "wrap" }}>
                                    <Button
                                        type="danger"
                                        block
                                        htmlType="submit"
                                        loading={processing}
                                        className="flex flex-row items-center justify-between rounded-lg"
                                        style={{ height: '45px' }}
                                        onClick={this.handleSubmit}>
                                        <span>Sign in</span>
                                        <RightOutlined />
                                    </Button>
                                </div>
                                <Col className="mt-3" span={24}>
                                    <Link
                                        to="/vendors/reset"
                                        disabled={processing}
                                        onClick={(e) => {
                                            if (processing) e.preventDefault();
                                        }}>
                                        <span className="font-semibold text-red-500 cursor-pointer">Forgot password?</span>
                                    </Link>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapDispatchToProps = {
    setUserData,
    clearUserData
};

export default connect(null, mapDispatchToProps)(VendorLogin);