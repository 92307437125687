export const GET_INVENTORY_CATEGORIES = {
    "TotalCount": 2,
    "CurrentPage": 1,
    "ExpectedPageSize": 10,
    "Results": [
        {
            "Id": 1,
            "Name": "Category 1"
        },
        {
            "Id": 2,
            "Name": "Longass category name"
        }
    ]
}

export const GET_ALL_INVENTORY_CATEGORIES = [
    {
        "Id": 1,
        "Name": "Category 1"
    },
    {
        "Id": 2,
        "Name": "Longass category name"
    }
]