import React, { Component } from "react";

class ContractIcon extends Component {
    render = () => {
        return (
            <svg
                height={24}
                width={24}
                viewBox="0 0 24 24"
                style={{ display: "inline" }}
            >
                <path
                    className="b"
                    d="M23.38 7.55283C22.5576 6.73036 21.2193 6.73041 20.3966 7.55302C20.1436 7.80628 18.528 9.42314 18.2812 9.67017V4.09809C18.2812 3.53466 18.0618 3.00497 17.6634 2.60658L15.6747 0.617813C15.2763 0.219375 14.7466 0 14.1832 0H2.10938C0.946266 0 0 0.946266 0 2.10938V21.8906C0 23.0537 0.946266 24 2.10938 24H16.1719C17.335 24 18.2812 23.0537 18.2812 21.8906V15.639L23.3799 10.536C24.2043 9.71161 24.2045 8.37727 23.38 7.55283ZM14.0625 1.40625C14.1954 1.40625 14.4514 1.38323 14.6804 1.61217L16.6691 3.60094C16.8922 3.82411 16.875 4.06866 16.875 4.21875H14.0625V1.40625ZM16.875 21.8906C16.875 22.2783 16.5596 22.5938 16.1719 22.5938H2.10938C1.72167 22.5938 1.40625 22.2783 1.40625 21.8906V2.10938C1.40625 1.72167 1.72167 1.40625 2.10938 1.40625H12.6562V4.92188C12.6562 5.31019 12.9711 5.625 13.3594 5.625H16.875V11.0776C16.875 11.0776 14.8006 13.1538 14.8005 13.1539L13.8066 14.1478C13.7295 14.225 13.6712 14.3191 13.6367 14.4226L12.6423 17.4058C12.5581 17.6584 12.6239 17.937 12.8122 18.1253C13.0008 18.3139 13.2795 18.3793 13.5317 18.2952L16.5149 17.3008C16.6184 17.2663 16.7125 17.2081 16.7897 17.1309L16.875 17.0456V21.8906ZM15.2981 14.645L16.2925 15.6394L15.9127 16.0192L14.4211 16.5164L14.9183 15.0248L15.2981 14.645ZM17.2867 14.6448L16.2923 13.6504C16.8218 13.1205 19.1735 10.7668 19.6722 10.2676L20.6666 11.262L17.2867 14.6448ZM22.3854 9.54178L21.6606 10.2672L20.6662 9.27286L21.3913 8.54719C21.6656 8.27302 22.1115 8.27306 22.3857 8.54719C22.6597 8.82141 22.6612 9.26592 22.3854 9.54178Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M13.3594 7.03125H3.51562C3.12731 7.03125 2.8125 7.34606 2.8125 7.73438C2.8125 8.12269 3.12731 8.4375 3.51562 8.4375H13.3594C13.7477 8.4375 14.0625 8.12269 14.0625 7.73438C14.0625 7.34606 13.7477 7.03125 13.3594 7.03125Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M10.5469 9.84375H3.51562C3.12731 9.84375 2.8125 10.1586 2.8125 10.5469C2.8125 10.9352 3.12731 11.25 3.51562 11.25H10.5469C10.9352 11.25 11.25 10.9352 11.25 10.5469C11.25 10.1586 10.9352 9.84375 10.5469 9.84375Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M10.5469 12.6562H3.51562C3.12731 12.6562 2.8125 12.9711 2.8125 13.3594C2.8125 13.7477 3.12731 14.0625 3.51562 14.0625H10.5469C10.9352 14.0625 11.25 13.7477 11.25 13.3594C11.25 12.9711 10.9352 12.6562 10.5469 12.6562Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M10.5469 15.4688H3.51562C3.12731 15.4688 2.8125 15.7836 2.8125 16.1719C2.8125 16.5602 3.12731 16.875 3.51562 16.875H10.5469C10.9352 16.875 11.25 16.5602 11.25 16.1719C11.25 15.7836 10.9352 15.4688 10.5469 15.4688Z"
                    fill="#5E5E5E"
                />
                <path
                    className="b"
                    d="M13.3594 19.7812H9.14062C8.75231 19.7812 8.4375 20.0961 8.4375 20.4844C8.4375 20.8727 8.75231 21.1875 9.14062 21.1875H13.3594C13.7477 21.1875 14.0625 20.8727 14.0625 20.4844C14.0625 20.0961 13.7477 19.7812 13.3594 19.7812Z"
                    fill="#5E5E5E"
                />
            </svg>
        )
    }
}

export default ContractIcon;
