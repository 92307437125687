export const GET_ALL_DOCUMENT_TYPES = [{
    "Id": 1,
    "TypeName": "Copy of a Certificate of Incorporation or Registration of Business Name or \nCertificate to Trade",
    "IsCompulsory": true,
    "HasExpiryDate": true,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 6,
    "TypeName": "Document showing shareholding filed at the public corporate registry in relevant \njurisdiction (certified true copy)",
    "IsCompulsory": true,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 8,
    "TypeName": "Document showing directors filed at the public corporate registry in relevant \njurisdiction (certified true copy)\n",
    "IsCompulsory": true,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 9,
    "TypeName": "Document evidencing and verifying registered address and/or principal place of \nbusiness (e.g. Utility Bill or Bank Letter)",
    "IsCompulsory": true,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 10,
    "TypeName": "Evidence of authorisation to sign the Contract (e.g. Power of Attorney, Resolution, \nletter of authorisation, etc)\n",
    "IsCompulsory": true,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 11,
    "TypeName": "Organisation Chart",
    "IsCompulsory": false,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 12,
    "TypeName": "3 (Three) Years Audited Financial Statements or Management Accounts",
    "IsCompulsory": false,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 13,
    "TypeName": "Ownership Structure",
    "IsCompulsory": false,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 14,
    "TypeName": "Completed Code of Conduct Third Party Attestation Form which is available here\nPlease click here to read our Code of Conduct",
    "IsCompulsory": false,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 15,
    "TypeName": "Completed Director/Owner KYC Form of each company director (or equivalent in \nyour jurisdiction of incorporation).",
    "IsCompulsory": true,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}, {
    "Id": 16,
    "TypeName": "Completed Director/Owner KYC Form of each of each individual owner of your \ncompany holding more than a 5% equity interest. ",
    "IsCompulsory": false,
    "HasExpiryDate": false,
    "VendorType": 1,
    "UserIdToManageDocument": 3
}]