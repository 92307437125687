import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { InventoryMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Inventories`;

export const getCurrencies = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_CURRENCIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCurrencies`, 'get');
}

export const getInventories = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_INVENTORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventories?${Util.objectToQueryString(params)}`, 'get');
}

export const getInventory = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_INVENTORY);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventory?${Util.objectToQueryString(params)}`, 'get');
}

export const createInventory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateInventory`, 'post', payload);
}

export const deleteInventory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteInventory`, 'post', payload);
}

export const getInventoryApprovalSettings = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_APPROVAL_SETTINGS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventoryApprovalSettings`, 'get');
}

export const updateInventoryApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateInventoryApprovalSetting`, 'post', payload);
}

export const deleteInventoryApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteInventoryApprovalSetting`, 'post', payload);
}

export const requestProduct = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RequestProduct`, 'post', payload);
}

export const restockInventory = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RestockInventory`, 'post', payload);
}

export const getMyPendingRequestsForApproval = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_MY_PENDING_REQUESTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetMyPendingRequestsForApproval?${Util.objectToQueryString(params)}`, 'get');
}


export const getMyInventoryRequests = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_MY_REQUESTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetMyInventoryRequests?${Util.objectToQueryString(params)}`, 'get');
}

export const getInventoryRequests = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_ALL_REQUESTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventoryRequests?${Util.objectToQueryString(params)}`, 'get');
}

//added on march 15 2023
export const getStaffName =  (id) => {
 const body = JSON.stringify({"ids":[id]});
 return  makeApiCall(`https://services.hh-converge.com/resources/api/staffs/id`, 'put', body,{'X-API-KEY' : 'fq05322d-429b-4f77-8a4p-a97ry62eb37k'});
}

export const getInventoryHistorys = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_INVENTORY_HISTORIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventoryHistorys?${Util.objectToQueryString(params)}`, 'get');
}

export const updateRequestedProduct = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateRequestedProduct`, 'post', payload);
}

export const updateRequestProductStatus = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateRequestProductStatus`, 'post', payload);
}

export const getInventorySummary = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(InventoryMock.GET_INVENTORY_SUMMARY);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetInventorySummary`, 'get');
}