import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button, Row, Col, Form, Input, Alert } from "antd";
import { RightOutlined } from '@ant-design/icons';

import { VendorAdapter } from '../../adapters';

class ChangePassword extends Component {
    passwordForm = React.createRef();
    state = {
        processing: false,
        processing_error: '',
        password_changed: false
    }

    handleChangePassword = () => {
        this.passwordForm.current.validateFields().then((values) => {
            this.setState({
                processing: true,
                processing_error: ''
            }, () => {
                VendorAdapter.changeVendorPassword({
                    EmailAddress: this.props.user.email,
                    Password: values.NewPassword
                }).then(() => {
                    this.passwordForm.current.resetFields();
                    //this.props.clearUserData();
                    this.setState({
                        processing: false,
                        password_changed: true
                    });
                }).catch((error) => {
                    this.setState({
                        processing: false,
                        processing_error: error.message
                    });
                });
            });
        }).catch((errors) => {
            console.log("Form validation errors: ", JSON.stringify(errors, null, 4));
        });
    }

    render = () => {
        const { processing, processing_error, password_changed } = this.state;
        return (
            <Modal
                bodyStyle={{ padding: 0 }}
                closable={false}
                width={420}
                visible={true}
                footer={null}>
                <div className="success-modal py-10 px-6 flex flex-col justify-center">
                    {!password_changed && (
                        <>
                            <h2 className="text-center">Update your password</h2>
                            <p className="py-5 description text-center">
                                You need to update your password because this is the first time you are signing
                                in, or because your account was reset.
                            </p>
                            {!!processing_error && (
                                <Row>
                                    <Col xs={24}>
                                        <div className="text-center mb-5">
                                            <Alert type="error" message={processing_error} closable />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Form
                                ref={this.passwordForm}
                                onSubmit={this.handleChangePassword}
                                hideRequiredMark
                                scrollToFirstError>
                                <Form.Item
                                    className="action-input"
                                    hasFeedback
                                    name="NewPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: "New password is required",
                                        },
                                        {
                                            min: 6,
                                            message: 'New password must be minimum 6 characters.'
                                        }
                                    ]}>
                                    <Input.Password
                                        name="NewPassword"
                                        placeholder="New Password"
                                        minLength={6}
                                        style={{ padding: '10px' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="action-input"
                                    hasFeedback
                                    name="ConfirmPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm your password",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('NewPassword') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('The two passwords that you entered do not match'));
                                            },
                                        })
                                    ]}>
                                    <Input.Password
                                        name="ConfirmPassword"
                                        placeholder="Confirm Password"
                                        minLength={6}
                                        style={{ padding: '10px' }}
                                    />
                                </Form.Item>
                                <div className="flex align-items-center mt-5" style={{ flexWrap: "wrap" }}>
                                    <Button
                                        type="danger"
                                        block
                                        htmlType="submit"
                                        loading={processing}
                                        className="flex flex-row items-center justify-between rounded-lg"
                                        style={{ height: '45px' }}
                                        onClick={this.handleChangePassword}>
                                        <span>Set Password</span>
                                        <RightOutlined />
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}
                    {password_changed && (
                        <>
                            <h2 className="text-center">Password sucessfully set</h2>
                            <p className="py-5 description text-center">
                                Your new password has been saved successfully.<br />
                                Kindly login again to continue using the platform
                            </p>
                            <div className="flex align-items-center mt-5" style={{ flexWrap: "wrap" }}>
                                <Link to="/vendors/login" style={{ width: '100%' }}>
                                    <Button type="danger"
                                        block
                                        className="flex flex-row items-center justify-between rounded-lg"
                                        style={{ height: '45px' }}>
                                        <span>Login</span>
                                        <RightOutlined />
                                    </Button>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user_data
    };
};

export default connect(mapStateToProps)(ChangePassword);