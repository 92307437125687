import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { setActiveModule } from '../../redux/actions/sidebar_actions';

class PrivateRoute extends Component {
    componentDidMount() {
        this.props.setActiveModule(this.props.name);
    }

    render = () => {
        const { user_data } = this.props.user;
        let valid_auth = (process.env.REACT_APP_MOCK === 'true') ||
            (user_data && user_data.access_token && moment(user_data.expires_at).isAfter());

        let valid_access = true;
        if (this.props.access) {
            valid_access = (this.props.access === 'all') || (user_data?.role === this.props.access);
            if (this.props.access === 'admin' && !user_data?.is_admin) {
                valid_access = false;
            }
        }

        let auth_redirect = user_data?.is_admin ? '/admin/expired-session' : '/vendors/login';
        if (valid_auth && valid_access) {
            return this.props.render();
        } else if (!valid_auth) {
            return <Redirect to={{ pathname: auth_redirect }} />
        } else if (!valid_access) {
            return <Redirect to={{ pathname: '/admin/unauthorized' }} />
        } else {
            // TODO Change to an unauthorized page with button to return to dashboard
            return <Redirect to={{ pathname: '/dashboard' }} />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = {
    setActiveModule
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);