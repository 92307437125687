import {
    START_LOAD_COUNTRIES, LOAD_COUNTRIES, LOAD_COUNTRIES_FAILED,
    START_LOAD_COMPANIES, LOAD_COMPANIES, LOAD_COMPANIES_FAILED,
    START_LOAD_VENDOR_CATEGORIES, LOAD_VENDOR_CATEGORIES, LOAD_VENDOR_CATEGORIES_FAILED,
    START_LOAD_VENDOR_TYPES, LOAD_VENDOR_TYPES, LOAD_VENDOR_TYPES_FAILED,
    START_LOAD_VENDOR_TYPE_QUESTIONS, LOAD_VENDOR_TYPE_QUESTIONS, LOAD_VENDOR_TYPE_QUESTIONS_FAILED,
    START_LOAD_VENDOR_DOCUMENT_TYPES, LOAD_VENDOR_DOCUMENT_TYPES, LOAD_VENDOR_DOCUMENT_TYPES_FAILED,
    START_LOAD_RESIDENCE_TYPES, LOAD_RESIDENCE_TYPES, LOAD_RESIDENCE_TYPES_FAILED,
    START_LOAD_COMPANY_TYPES, LOAD_COMPANY_TYPES, LOAD_COMPANY_TYPES_FAILED,
    START_LOAD_QUESTION_CATEGORIES, LOAD_QUESTION_CATEGORIES, LOAD_QUESTION_CATEGORIES_FAILED,
    START_LOAD_USERS, LOAD_USERS, LOAD_USERS_FAILED,
    START_LOAD_BANKS, LOAD_BANKS, LOAD_BANKS_FAILED,
    START_LOAD_COMPANY_ROLES, LOAD_COMPANY_ROLES, LOAD_COMPANY_ROLES_FAILED,
    START_LOAD_INVENTORY_CATEGORIES, LOAD_INVENTORY_CATEGORIES, LOAD_INVENTORY_CATEGORIES_FAILED,
    START_LOAD_CURRENCIES, LOAD_CURRENCIES, LOAD_CURRENCIES_FAILED,
    START_LOAD_CONTRACT_TEMPLATES, LOAD_CONTRACT_TEMPLATES, LOAD_CONTRACT_TEMPLATES_FAILED,
    START_LOAD_CONTRACT_TEMPLATE_TAGS, LOAD_CONTRACT_TEMPLATE_TAGS, LOAD_CONTRACT_TEMPLATE_TAGS_FAILED,
    START_LOAD_CONTRACT_TYPES, LOAD_CONTRACT_TYPES, LOAD_CONTRACT_TYPES_FAILED
} from '../types/config_types';

import {
    VendorAdapter,
    VendorCategoryAdapter,
    AttachedDocumentTypeAdapter,
    InventoryAdapter,
    InventoryCategoryAdapter,
    ContractAdapter
} from '../../adapters';

export const loadCountries = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_COUNTRIES
        });
        VendorAdapter.getCountries()
            .then((result) => {
                dispatch({
                    countries: result,
                    type: LOAD_COUNTRIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_COUNTRIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadGroupCompanies = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_COMPANIES
        });
        VendorAdapter.getCompanies()
            .then((result) => {
                dispatch({
                    group_companies: result,
                    type: LOAD_COMPANIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_COMPANIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadVendorCategories = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_VENDOR_CATEGORIES
        });
        VendorCategoryAdapter.getAllVendorCategories()
            .then((result) => {
                dispatch({
                    vendor_categories: result,
                    type: LOAD_VENDOR_CATEGORIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_VENDOR_CATEGORIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadVendorTypes = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_VENDOR_TYPES
        });
        VendorAdapter.getVendorTypes()
            .then((result) => {
                dispatch({
                    vendor_types: result,
                    type: LOAD_VENDOR_TYPES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_VENDOR_TYPES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadVendorTypeQuestions = (params) => {
    return dispatch => {
        dispatch({
            type: START_LOAD_VENDOR_TYPE_QUESTIONS
        });
        VendorAdapter.getVendorTypeQuestionsByVendor(params)
            .then((result) => {
                dispatch({
                    vendor_type_questions: result,
                    type: LOAD_VENDOR_TYPE_QUESTIONS
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_VENDOR_TYPE_QUESTIONS_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadVendorDocumentTypes = (params) => {
    return dispatch => {
        dispatch({
            type: START_LOAD_VENDOR_DOCUMENT_TYPES
        });
        AttachedDocumentTypeAdapter.getAllAttachedDocumentTypesByVendor(params)
            .then((result) => {
                dispatch({
                    vendor_document_types: result,
                    type: LOAD_VENDOR_DOCUMENT_TYPES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_VENDOR_DOCUMENT_TYPES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadResidenceTypes = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_RESIDENCE_TYPES
        });
        VendorAdapter.getResidenceTypes()
            .then((result) => {
                dispatch({
                    residence_types: result,
                    type: LOAD_RESIDENCE_TYPES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_RESIDENCE_TYPES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadCompanyTypes = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_COMPANY_TYPES
        });
        VendorAdapter.getCompanyTypes()
            .then((result) => {
                dispatch({
                    company_types: result,
                    type: LOAD_COMPANY_TYPES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_COMPANY_TYPES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadQuestionCategories = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_QUESTION_CATEGORIES
        });
        VendorAdapter.getQuestionCategories()
            .then((result) => {
                dispatch({
                    question_categories: result,
                    type: LOAD_QUESTION_CATEGORIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_QUESTION_CATEGORIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadUsers = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_USERS
        });
        VendorAdapter.getUsers()
            .then((result) => {
                dispatch({
                    users: result,
                    type: LOAD_USERS
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_USERS_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadBanks = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_BANKS
        });
        VendorAdapter.getBanks()
            .then((result) => {
                dispatch({
                    banks: result,
                    type: LOAD_BANKS
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_BANKS_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadCompanyRoles = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_COMPANY_ROLES
        });
        VendorAdapter.getCompanyRoles()
            .then((result) => {
                dispatch({
                    company_roles: result,
                    type: LOAD_COMPANY_ROLES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_COMPANY_ROLES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadInventoryCategories = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_INVENTORY_CATEGORIES
        });
        InventoryCategoryAdapter.getAllInventoryCategories()
            .then((result) => {
                dispatch({
                    inventory_categories: result,
                    type: LOAD_INVENTORY_CATEGORIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_INVENTORY_CATEGORIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadCurrencies = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_CURRENCIES
        });
        InventoryAdapter.getCurrencies()
            .then((result) => {
                dispatch({
                    currencies: result,
                    type: LOAD_CURRENCIES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_CURRENCIES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadContractTemplateTags = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_CONTRACT_TEMPLATE_TAGS
        });
        ContractAdapter.getContractTemplateTags()
            .then((result) => {
                dispatch({
                    template_tags: result,
                    type: LOAD_CONTRACT_TEMPLATE_TAGS
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_CONTRACT_TEMPLATE_TAGS_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadContractTemplates = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_CONTRACT_TEMPLATES
        });
        ContractAdapter.getContractTemplates()
            .then((result) => {
                dispatch({
                    templates: result,
                    type: LOAD_CONTRACT_TEMPLATES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_CONTRACT_TEMPLATES_FAILED,
                    error_message: error.message
                });
            });
    };
};

export const loadContractTypes = () => {
    return dispatch => {
        dispatch({
            type: START_LOAD_CONTRACT_TYPES
        });
        ContractAdapter.getContractTypes()
            .then((result) => {
                dispatch({
                    contract_types: result,
                    type: LOAD_CONTRACT_TYPES
                })
            }).catch((error) => {
                dispatch({
                    type: LOAD_CONTRACT_TYPES_FAILED,
                    error_message: error.message
                });
            });
    };
};