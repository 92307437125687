import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { BlobServiceClient } from "@azure/storage-blob";

export const objectToQueryString = (params) => {
    let queryParams = [];

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            if (element) {
                queryParams.push(`${key}=${encodeURI(element)}`);
            }
        }
    }

    return queryParams.join("&");
};

export const userFromAdminToken = (admin_token) => {
    const token_data = decode(admin_token);
    const { id, name, email, companyId, companyName, imgUrl, companyLogoUrl, exp, isAdmin, Privileges } = token_data;
    return {
        id,
        name,
        email,
        profile_image: imgUrl,
        company_id: companyId,
        company_name: companyName,
        company_logo: companyLogoUrl,
        access_token: admin_token,
        expires_at: moment(exp * 1000),
        role: 'admin',
        privileges: Privileges,
        is_admin: JSON.parse(isAdmin?.toString().toLowerCase()) // We have admins with valid tokens who are not actually admins. We use the flag in token data to do a second check
    }
}

export const userFromVendorLogin = (login_response, vendor_profile) => {
    const { Id: id,
        VendorName: name,
        InitiatingCompanyName: company_name,
        ContactFirstname: contact_firstname,
        ContactSurname: contact_surname,
        ContactPhoneNumber: contact_phone
    } = vendor_profile;
    const {
        JWTToken: access_token,
        AcceptTermAndConditions: has_accepted_terms,
        IsVendorRegistrationPending: is_registration_pending,
        IsVendorApproved: is_approved,
        IsVendorRejected: is_rejected,
        IsVendorBlacklisted: is_blacklisted,
        MustChangePassword: must_change_password,
        ExpiringDate
    } = login_response;
    const token_data = decode(access_token);
    const { VendorEmail: email, VendorTypeId: vendor_type_id, VendorType: vendor_type_name, companyLogoUrl: company_logo } = token_data;

    return {
        id,
        name,
        email,
        vendor_type_id,
        vendor_type_name,
        company_logo,
        company_name,
        contact_name: `${contact_surname} ${contact_firstname}`,
        contact_phone,
        access_token,
        expires_at: moment(ExpiringDate),
        role: 'vendors',
        has_accepted_terms,
        is_registration_pending,
        is_approved,
        is_rejected,
        is_blacklisted,
        must_change_password
    };
}

export const determineVendorRoute = (login_response) => {
    if (!login_response.AcceptTermAndConditions) {
        return '/vendors/signup';
    } else if (login_response.IsVendorRegistrationPending) {
        return '/vendors/registration';
    } else {
        return '/';
    }
}

export const initAzureContainer = () => {
    const account = process.env.REACT_APP_AZURE_ACCT_NAME;
    const sas = process.env.REACT_APP_AZURE_SAS_TOKEN;
    const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;

    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    return containerClient;
};

export const blobToString = async (blob) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
            resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(blob);
    });
}

export const normalizeMobileNumber = (phoneNumber) => {
    phoneNumber = phoneNumber ? phoneNumber.replace("+234", "") : "";
    if (phoneNumber && phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(phoneNumber.length - 10);
    }
    return phoneNumber;
}

export const categoryGroupQuestions = (questions) => {
    let question_groups = [];

    const per_category = questions
        .reduce((per_category, question) => ({
            ...per_category,
            [question.QuestionCategoryName]:
                [...(per_category[question.QuestionCategoryName] || []), question]
        }), {});

    for (const category in per_category) {
        let questions = per_category[category];
        questions.sort((a, b) => a.OrderBy - b.OrderBy);

        let question_group = {
            category,
            questions
        };
        question_groups.push(question_group);
    }

    return question_groups;
}

export const isValidEmail = (email) => {
    return !!email && (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
}

export const formatAmount = (amount) => {
    if (+amount) {
        return parseFloat(amount).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
        return '0.00'
    }
}

export const vendorRegistrationTabs = {
    contact_form: "contact-information",
    kyc: "KYC",
    bank_account: "bank-details",
    document_upload: "document-upload",
    code_conduct: "code-of-conduct"
}

export const vendorViewTabs = {
    company: {
        viewKey: "company-info",
        dataKey: "always"
    },
    documents: {
        viewKey: "documents",
        dataKey: "always"
    },
    contacts: {
        viewKey: "contact-info",
        dataKey: "Contacts"
    },
    answers: {
        viewKey: "KYC",
        dataKey: "Answers"
    },
    code_conduct: {
        viewKey: "code-of-conduct",
        dataKey: "always"
    }
}

export const adminSettingsTabs = {
    // vendor_categories: "vendor-categories",
    vendor_questions: "vendor-kyc",
    vendor_documents: "vendor-documents",
    vendor_approval: "vendor-approval",
    procurement_committees: "procurement-committees",
    contract_templates: "contract-templates",
    contract_approval: "contract-approval",
    inventory_categories: "inventory-categories",
    inventory_products: "inventory-products",
    inventory_approval: "inventory-approval"
}

export const statusConfig = {
    "pending": {
        color: "#D8A200",
        background: "#FFF8E3"
    },
    "inhousepending": {
        color: "#D8A200",
        background: "#FFF8E3"
    },
    "pendinginvoice": {
        color: "#D8A200",
        background: "#FFF8E3"
    },
    "paymentinitiated": {
        color: "#096DD9",
        background: "#E6F7FF"
    },
    "approved": {
        color: "#219653",
        background: "#E9F7E9"
    },
    "paid": {
        color: "#219653",
        background: "#E9F7E9"
    },
    "sent": {
        color: "#219653",
        background: "#E9F7E9"
    },
    "queried": {
        color: "#096DD9",
        background: "#E6F7FF"
    },
    "rejected": {
        color: "#E90606",
        background: "#FFECEC"
    },
    "cancelled": {
        color: "#5E5E5E",
        background: "#EAEAEA"
    },
    "blacklisted": {
        color: "#CF1322",
        background: "#FFF1F0"
    },
    "awaiting approval": {
        color: "#096DD9",
        background: "#E6F7FF"
    },
    "done": {
        color: "#219653",
        background: "#E9F7E9"
    },
    "invoicesubmitted": {
        color: "#096DD9",
        background: "#E6F7FF"
    },
    "terminated": {
        color: "#096DD9",
        background: "#E6F7FF"
    },
    "partially approved": {
        color: "#D8A200",
        background: "#FFF8E3"
    }
}

export const procurementViewTabs = {
    feedback: "vendor's-feedback",
    recommendation: "recommendation"
}

export const editorToolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
    },
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Mulish']
    },
}

export const contractStages = {
    contract_type: 'contract_type',
    contract_form: 'contract_form'
};

export const adminContractViewTabs = {
    details: "contract-details",
    progress: "contract-progress",
    document: "document"
}

export const vendorContractViewTabs = {
    progress: "contract-status",
    document: "document"
}

export const downloadContractWord = (contract) => {
    const input = document.getElementById('formatted_contract');
    const header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Download Contract</title></head><body>";
    const footer = "</body></html>";
    const source_html = header + input.innerHTML + footer;
    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(source_html);
    var file_download = document.createElement("a");
    document.body.appendChild(file_download);
    file_download.href = source;
    file_download.download = `${contract.Title || contract.ProjectTaskName}.doc`;
    file_download.click();
    document.body.removeChild(file_download);
}