import { SET_VISIBILITY, SET_ACTIVE_MODULE } from '../types/sidebar_types';

const initialState = {
    visibility: false,
    active_module: ''
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISIBILITY: {
            return {
                ...state,
                visibility: action.visibility
            }
        }
        case SET_ACTIVE_MODULE: {
            return {
                ...state,
                active_module: action.active_module
            }
        }
        default: {
            return state;
        }
    }
};

export default sidebarReducer;