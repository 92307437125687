import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { ProcurementMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Procurements`;

export const getProcurementSummaries = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_PROCUREMENT_SUMMARIES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetProcurementSummaries`, 'get');
}

export const getProcurements = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_PROCUREMENTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetProcurements?${Util.objectToQueryString(params)}`, 'get');
}

export const getProcurementById = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_PROCUREMENT_BY_ID);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetProcurementById?${Util.objectToQueryString(params)}`, 'get');
}

export const getCommittees = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_COMMITTEES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetCommittees?${Util.objectToQueryString(params)}`, 'get');
}

export const createCommittee = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateCommittee`, 'post', payload);
}

export const addMemberToCommittee = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/AddMemberToCommittee`, 'post', payload);
}

export const getAllCommitteeMembers = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_COMMITTEE_MEMBERS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetAllCommitteeMembers?${Util.objectToQueryString(params)}`, 'get');
}

export const createProcurement = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateProcurement`, 'post', payload);
}

export const getVendorProcurements = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_VENDOR_PROCUREMENTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorProcurements?${Util.objectToQueryString(params)}`, 'get');
}

export const getVendorProcurementById = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_VENDOR_PROCUREMENT_BY_ID);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorProcurementById?${Util.objectToQueryString(params)}`, 'get');
}

export const vendorSubmitRFQ = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/VendorSubmitRFQ`, 'post', payload);
}

export const committeeSubmitFeedBacktoRFQ = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CommitteeSubmitFeedBacktoRFQ`, 'post', payload);
}

export const setAdminToApproveRFQSubmission = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/SetAdminToApproveRFQSubmission`, 'post', payload);
}

export const approveRFQSubmission = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/ApproveRFQSubmission`, 'post', payload);
}

export const declineRFQSubmission = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeclineRFQSubmission`, 'post', payload);
}

export const getApprovedProjectTasks = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ProcurementMock.GET_APPROVED_PROJECT_TASKS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetApprovedProjectTasks?${Util.objectToQueryString(params)}`, 'get');
}