import React, { Component } from "react";

class PendingUploadIcon extends Component {
    render = () => {
        return (
            <svg
                height={28}
                width={24}
                viewBox="0 0 24 28"
                style={{ display: "inline" }}>
                <path
                    d="M17.3464 19.5707C17.1515 19.276 16.7339 19.217 16.4833 19.4823L15.8151 20.1602C15.5645 20.4254 15.1747 20.3665 14.952 20.1012L12.836 17.0654C12.5854 16.7118 12.1121 16.7412 11.8893 17.0949L8.24199 23.2549C7.99141 23.6675 8.26984 24.1981 8.71531 24.1981H19.351C19.8244 24.1981 20.1028 23.6381 19.8244 23.2254L17.3464 19.5707Z"
                    fill="#BDBDBD"
                />
                <path
                    d="M17.9019 17.6262C18.7169 17.6262 19.3775 16.9268 19.3775 16.0641C19.3775 15.2013 18.7169 14.502 17.9019 14.502C17.0869 14.502 16.4263 15.2013 16.4263 16.0641C16.4263 16.9268 17.0869 17.6262 17.9019 17.6262Z"
                    fill="#BDBDBD"
                />
                <path
                    d="M23.7494 10.6695L18.1531 4.71579C17.9861 4.53895 17.7633 4.45053 17.5406 4.45053L12.3619 4.42105C11.471 1.85684 9.13225 0 6.37587 0C2.86775 0.0294737 0 3.06526 0 6.77895C0 9.66737 1.72622 12.1432 4.14849 13.0863V25.8779C4.14849 27.0568 5.03944 28 6.15313 28H21.9954C23.1091 28 24 27.0568 24 25.8779V11.2884C24 11.0526 23.9165 10.8168 23.7494 10.6695ZM18.4037 7.57474L21.0766 10.4042H18.4037V7.57474ZM1.72622 6.77895C1.72622 4.06737 3.81439 1.82737 6.40371 1.82737C8.99304 1.82737 11.0812 4.03789 11.0812 6.77895C11.0812 9.52 8.99304 11.7305 6.40371 11.7305C3.81439 11.7305 1.72622 9.52 1.72622 6.77895ZM21.9954 26.2021H6.15313C5.98608 26.2021 5.87471 26.0547 5.87471 25.9074V13.5284C6.04176 13.5579 6.20882 13.5579 6.40371 13.5579C9.91183 13.5579 12.7796 10.5221 12.7796 6.80842C12.7796 6.63158 12.7796 6.45474 12.7517 6.27789H16.6775V11.2884C16.6775 11.7895 17.0673 12.2021 17.5406 12.2021H22.2738V25.8779C22.2738 26.0547 22.1346 26.2021 21.9954 26.2021Z"
                    fill="#BDBDBD"
                />
                <path
                    d="M7.23823 9.22526V6.54316L7.43312 6.72C7.60017 6.89684 7.82291 6.95579 8.01781 6.95579C8.24055 6.95579 8.49113 6.86737 8.65818 6.66105C8.99229 6.30737 8.96444 5.71789 8.60249 5.36421L6.9598 3.74316C6.6257 3.41895 6.12454 3.41895 5.79043 3.74316L4.17558 5.36421C3.84147 5.71789 3.81363 6.27789 4.14774 6.66105C4.48184 7.01474 5.01085 7.04421 5.3728 6.69053L5.56769 6.51368V9.19579C5.56769 9.69684 5.95748 10.1095 6.4308 10.1095C6.90412 10.1095 7.23823 9.72632 7.23823 9.22526Z"
                    fill="#BDBDBD"
                />
            </svg>
        )
    }
}

export default PendingUploadIcon;
