import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Empty } from "antd";
import { CloudDownloadOutlined, PlusOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';

import { CustomTable } from "../molecules";

class TableData extends Component {
    render = () => {
        const {
            addNewPath,
            addNewLabel,
            addNewAction,
            loading,
            loadingMessage,
            loadError,
            noCount,
            emptyDataMessage,
            tableColumns,
            tableData,
            onShowItem,
            onRefresh,
            onPageChange,
            filterLabel,
            filterOptions,
            onFilter,
            activeFilter,
            customFilter,
            onDownload } = this.props;
        const {
            TotalCount: total_count, CurrentPage: page_number, ExpectedPageSize: page_size, Results: table_entries
        } = tableData || {};
        return (
            <div className="px-5">
                <div className="table-control flex flex-row justify-between p-4 border-b border-gray-200">
                    <div className="show-count dark-grey flex flex-row items-center">
                        {noCount ? '' : `Showing ${total_count || 0} Results`}
                    </div>
                    <div className="control-buttons">
                        <div className="status-filter flex flex-row items-center">
                            {!customFilter && !!onFilter && filterOptions?.length > 0 && (
                                <div className="flex flex-row">
                                    <span className="mr-4 dark-grey flex flex-row items-center">{filterLabel}:</span>
                                    {filterOptions.map(
                                        (option, index) => (
                                            <Button key={index}
                                                type={option.value === activeFilter ? 'danger' : ''}
                                                ghost={option.value === activeFilter ? true : false}
                                                className="table-filter"
                                                onClick={() => onFilter(option.value)}
                                                disabled={loading}>
                                                {option.label}
                                            </Button>
                                        )
                                    )}
                                </div>
                            )}
                            {!!customFilter && (
                                <>{customFilter}</>
                            )}
                            {!!onDownload && (
                                <Button
                                    className="flex flex-row default-btn items-center justify-between ml-6 rounded-lg"
                                    onClick={() => onDownload()}
                                    disabled={loading} style={{ color: '#5e5e5e' }}>
                                    <span>Download</span>
                                    <CloudDownloadOutlined style={{ fontSize: 24, color: '#5e5e5e' }} />
                                </Button>
                            )}
                            {!!addNewPath && !addNewAction && (
                                <Link to={addNewPath}
                                    onClick={(e) => {
                                        if (loading) e.preventDefault();
                                    }}>
                                    <Button
                                        type="danger"
                                        className="flex flex-row items-center justify-between ml-4 rounded-lg">
                                        <span>{addNewLabel || 'Add'}</span>
                                        <PlusOutlined />
                                    </Button>
                                </Link>
                            )}
                            {!!addNewAction && (
                                <Button
                                    type="danger"
                                    className="flex flex-row items-center justify-between ml-4 rounded-lg"
                                    onClick={addNewAction}>
                                    {addNewLabel || 'Add'}
                                    <PlusOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <Row>
                    {!!loading ? (
                        <div className="full-width py-10 text-center">
                            <LoadingOutlined className="loader" />
                            <br />
                            {loadingMessage || 'loading'}
                        </div>
                    ) : !!loadError ? (
                        <div className="full-width py-10 text-center">
                            <WarningOutlined className="loader" style={{ fontSize: 48 }} />
                            <br />
                            {loadError}
                            <br />
                            <Button
                                className="my-4 rounded-lg default-btn"
                                onClick={() => onRefresh()}>
                                <span>Retry Now</span>
                            </Button>
                        </div>
                    ) : total_count ? (
                        <div className="pt-8 full-width">
                            <CustomTable
                                rowKey="Id"
                                onRow={(record) => ({
                                    onClick: () => {
                                        onShowItem && onShowItem(record);
                                    },
                                })}
                                loading={loading}
                                columns={tableColumns}
                                data={table_entries}
                                paginated
                                paginationProps={{
                                    currentPage: page_number + 1,
                                    onChange: onPageChange,
                                    size: page_size,
                                    totalElements: total_count,
                                    totalPages: Math.ceil(total_count / page_size),
                                    position: "bottom"
                                }}
                            />
                        </div>
                    ) : (
                        <div className="full-width py-10 text-center">
                            <Empty className="mt-20" description={emptyDataMessage || 'There are no items to display'} />
                            <Button
                                className="my-4 rounded-lg default-btn"
                                onClick={() => onRefresh()}>
                                <span>Refresh</span>
                            </Button>
                        </div>
                    )}
                </Row>
            </div>
        );
    }
}

export default TableData;
