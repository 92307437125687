import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { Row } from "antd";
import { DisconnectOutlined } from '@ant-design/icons';

import { clearUserData } from '../../../../redux/actions/user_actions';

class ExpiredToken extends Component {
    componentDidMount = () => {
        this.props.clearUserData();
    }

    render = () => {
        return (
            <>
                <Helmet title={`Procurement ${this.props.name ? `| ${this.props.name}` : ''}`} />
                <Row type="flex" justify="center" align="middle"
                    style={{
                        padding: 20,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        minHeight: '100vh'
                    }}>
                    <DisconnectOutlined style={{
                        fontSize: '5em',
                        marginBottom: '10px',
                        opacity: 0.7
                    }} />
                    <div className="text-center">Your session has expired.<br />Kindly login again on Converge to start a new session.</div>
                </Row>
            </>
        );
    }
}

const mapDispatchToProps = {
    clearUserData
};

export default connect(null, mapDispatchToProps)(ExpiredToken);
