import React, { Component } from "react";
import { Button } from "antd";
import Search from "antd/lib/input/Search";
import { LeftOutlined } from '@ant-design/icons';

class DrawerHeader extends Component {
    render = () => {
        const { title, onClose, onSearch, searchPlaceholder, searchClass, onSearchTermChange, loading, disabled, actionButtons } = this.props;
        return (
            <div className="px-8 py-3 border-b border-gray-200 flex flex-row flex-wrap justify-between items-center">
                <h1 className="mb-3 md:mb-0 text-gray-700 page-title flex flex-row items-center">
                    <Button className="action-back" size="large" onClick={onClose} disabled={disabled}>
                        <LeftOutlined />
                    </Button>
                    <span className="ml-5 page-title">{title}</span>
                </h1>
                {!!actionButtons && !loading && (actionButtons)}
                {!!onSearch && (
                    <Search
                        onSearch={onSearch}
                        style={{ width: 300 }}
                        placeholder={searchPlaceholder || 'Enter text to search'}
                        className={`border-gray-200 nav-search ${searchClass || ''}`}
                        disabled={disabled}
                        onChange={(e) => {
                            if (!!onSearchTermChange) {
                                onSearchTermChange(e.target.value);
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}

export default DrawerHeader;
