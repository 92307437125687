import { Util } from '../utils';
import { makeApiCall } from './xhr';
import { ContractMock } from './mocks';

const USE_MOCK = process.env.REACT_APP_MOCK === 'true';
const BASE_URL = `${process.env.REACT_APP_API_URL}/Contract`;

export const getContracts = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_CONTRACTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContracts?${Util.objectToQueryString(params)}`, 'get');
}

export const getContractTemplates = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_CONTRACT_TEMPLATES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContractTemplates`, 'get');
}

export const getContractTemplateTags = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_CONTRACT_TEMPLATE_TAGS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContractTemplateTags`, 'get');
}

export const createContractTemplate = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateContractTemplate`, 'post', payload);
}

export const updateContractTemplate = createContractTemplate;

export const deleteContractTemplate = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/DeleteContractTemplate`, 'post', payload);
}

export const getContractTypes = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_CONTRACT_TYPES);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContractTypes`, 'get');
}

export const createContract = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/CreateContract`, 'post', payload);
}

export const getContractApprovalSettings = async () => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_APPROVAL_SETTINGS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContractApprovalSetting`, 'get');
}

export const updateContractApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateContractApprovalSetting`, 'post', payload);
}

export const removeAdminFromContractApprovalSetting = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RemoveAdminFromContractApprovalSetting`, 'post', payload);
}

export const getVendorContracts = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_VENDOR_CONTRACTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorContracts?${Util.objectToQueryString(params)}`, 'get');
}

export const getContractById = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_CONTRACT_BY_ID);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetContract?${Util.objectToQueryString(params)}`, 'get');
}

export const updateContract = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateContract`, 'post', payload);
}

export const requestVendorContractFeedback = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RequestVendorContractFeedback`, 'post', payload);
}

export const getVendorContractById = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_VENDOR_CONTRACT_BY_ID);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetVendorContract?${Util.objectToQueryString(params)}`, 'get');
}

export const updateVendorContractStatus = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateVendorContractStatus`, 'post', payload);
}

export const updateContractStatus = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UpdateContractStatus`, 'post', payload);
}

export const requestInvoiceFromVendor = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/RequestInvoiceFromVendor`, 'post', payload);
}

export const uploadInvoice = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve();
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/UploadInvoice`, 'post', payload);
}

export const getExistingTaskContracts = async (params) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(ContractMock.GET_EXISTING_TASK_CONTRACTS);
            }, 1000)
        );
    }

    return makeApiCall(`${BASE_URL}/GetExistingTaskContracts?${Util.objectToQueryString(params)}`, 'get');
}